import { Link } from 'react-router-dom'

import toDo from '../images/todo.JPG'
import chat from '../images/chat.JPG'
import calculator from '../images/calculator.JPG'
import counter from '../images/counter.jpg'
import weather from '../images/Weather_app.JPG'
import quiz from '../images/quiz.jpg'
import movie from '../images/move-app.jpg'
import password from '../images/password-generator.jpg'

const Projects = () => {
  return (
    <div className='bg-gray-300 min-h-[85vh] lg:w-[1100px] sm:w-[100vw] lg:mx-auto sm:mx-4 relative p-4'>
        
        <h1 className='mt-32 mb-6 text-gray-800 text-6xl animate-[insert_0.5s_ease-in-out]'>Progetti</h1>

        <div className='w-full p-6 my-10 bg-gray-100 border-2 border-gray-200 shadow-md rounded-2xl flex justify-between items-center animate-[insert_0.5s_ease-in-out]'>
          <div className='lg:w-1/2 w-full lg:mr-4 mr-0'>
            <h1 className='text-gray-800 text-3xl font-semibold mb-4'>Counter App</h1>
            <p className='text-lg mb-4'>Counter app realizzata nella parte backend con Node.js e il pacchetto Express e database MondoDB. Completa anche dell'utenticazione utente e registrazione. Nella parte frontend è stato usato il framework React con Redux.</p>
            <p className='text-lg mb-4'>Semplice app per creare conti alla rovescia!</p>
            <h4 className='text-xl font-semibold mb-8'>React, Redux, Node.js, Express, MongoDB.</h4>
            <a
              href="https://counter-app-rv.herokuapp.com/"
              target="_blank"
              className='py-3 px-6 bg-indigo-600 text-white text-lg hover:bg-indigo-700 rounded'
              rel="noreferrer"
            >Vai al progetto</a>
          </div>
          <img
            src={counter}
            alt=""
            className='w-1/2 shadow-lg shadow-gray-500 lg:block hidden'
          />
        </div>

        <div className='w-full p-6 my-10 bg-gray-100 border-2 border-gray-200 shadow-md rounded-2xl flex justify-between items-center animate-[insert_0.5s_ease-in-out]'>
          <div className='lg:w-1/2 w-full lg:mr-4 mr-0'>
            <h1 className='text-gray-800 text-3xl font-semibold mb-4'>To Do App</h1>
            <p className='text-lg mb-4'>To do app realizzata nella parte backend con Node.js e il pacchetto Express e database MondoDB. Completa anche dell'utenticazione utente e registrazione. Nella parte frontend è stato usato il framework React con Redux.</p>
            <p className='text-lg mb-4'>Crea un account e inizia a creare i tuoi Todo! Ad ogni Todo può essere assegnata una categoria in modo da rendere più facile il filtraggio dei tuoi Todo.</p>
            <h4 className='text-xl font-semibold mb-8'>React, Redux, Node.js, Express, MongoDB.</h4>
            <a
              href="https://to-do-app-riccardo.herokuapp.com"
              target="_blank"
              className='py-3 px-6 bg-indigo-600 text-white text-lg hover:bg-indigo-700 rounded'
              rel="noreferrer"
            >Vai al progetto</a>
          </div>
          <img
            src={toDo}
            alt=""
            className='w-1/2 shadow-lg shadow-gray-500 lg:block hidden'
          />
        </div>

        <div className='w-full p-6 my-10 bg-gray-100 border-2 border-gray-200 shadow-md rounded-2xl flex justify-between items-center animate-[insert_0.5s_ease-in-out]'>
          <div className='lg:w-1/2 w-full lg:mr-4 mr-0'>
            <h1 className='text-gray-800 text-3xl font-semibold mb-4'>Chat App</h1>
            <p className='text-lg mb-4'>Applicazione chat live realizzata su Node.js con le librerie Express e Socket.io. Utilizza anche il pacchetto bad-words per il controllo delle parole.</p>
            <p className='text-lg mb-4'>Inserisci il nome di una stanza e assicurati che le persone con cui vuoi chattare inseriscano lo stesso, poi un usernarme a tua scelta e sei pronto!</p>
            <h4 className='text-xl font-semibold mb-8'>HTML, CSS, Node.js, Express, Socket.io.</h4>
            <a
              href="https://chat-app-rv.herokuapp.com/"
              target="_blank"
              className='py-3 px-6 bg-indigo-600 text-white text-lg hover:bg-indigo-700 rounded'
              rel="noreferrer"
            >Vai al progetto</a>
          </div>
          <img
            src={chat}
            alt=""
            className='w-1/2 shadow-lg shadow-gray-500 lg:block hidden'
          />
        </div>

        <div className='w-full p-6 my-10 bg-gray-100 border-2 border-gray-200 shadow-md rounded-2xl flex justify-between items-center animate-[insert_0.5s_ease-in-out]'>
          <div className='lg:w-1/2 w-full lg:mr-4 mr-0'>
            <h1 className='text-gray-800 text-3xl font-semibold mb-4'>Weather App</h1>
            <p className='text-lg mb-4'>Applicazione per ottenere le condizioni meteo attuali di una località.</p>
            <p className='text-lg mb-4'>Inserisci il nome della località di cui vuoi sapere le condizioni del meteo attuali ed il gioco è fatto!</p>
            <h4 className='text-xl font-semibold mb-8'>HTML, CSS, Node.js</h4>
            <a
              href="https://rv-weather-app.herokuapp.com/"
              target="_blank"
              className='py-3 px-6 bg-indigo-600 text-white text-lg hover:bg-indigo-700 rounded'
              rel="noreferrer"
            >Vai al progetto</a>
          </div>
          <img
            src={weather}
            alt=""
            className='w-1/2 shadow-lg shadow-gray-500 lg:block hidden'
          />
        </div>

        <div className='w-full p-6 my-10 bg-gray-100 border-2 border-gray-200 shadow-md rounded-2xl flex justify-between items-center animate-[insert_0.5s_ease-in-out]'>
          <div className='lg:w-1/2 w-full lg:mr-4 mr-0'>
            <h1 className='text-gray-800 text-3xl font-semibold mb-4'>Quiz App</h1>
            <p className='text-lg mb-4'>Rispondi alle domande che ti vengono poste e alla fine vedrai il punteggio che hai ottenuto.</p>
            <h4 className='text-xl font-semibold mb-8'>HTML, CSS, Javascript.</h4>
            <Link
              to="/quiz-app"
              className='py-3 px-6 bg-indigo-600 text-white text-lg hover:bg-indigo-700 rounded'
            >Vai al progetto</Link>
          </div>
          <img
            src={quiz}
            alt=""
            className='w-1/2 shadow-lg shadow-gray-500 lg:block hidden'
          />
        </div>

        <div className='w-full p-6 my-10 bg-gray-100 border-2 border-gray-200 shadow-md rounded-2xl flex justify-between items-center animate-[insert_0.5s_ease-in-out]'>
          <div className='lg:w-1/2 w-full lg:mr-4 mr-0'>
            <h1 className='text-gray-800 text-3xl font-semibold mb-4'>Calcolatrice</h1>
            <p className='text-lg mb-4'>Calcolatrice per realizzare operazione aritmetiche.</p>
            <h4 className='text-xl font-semibold mb-8'>HTML, CSS, Javascript.</h4>
            <Link
              to="/calculator"
              className='py-3 px-6 bg-indigo-600 text-white text-lg hover:bg-indigo-700 rounded'
            >Vai al progetto</Link>
          </div>
          <img
            src={calculator}
            alt=""
            className='w-1/2 shadow-lg shadow-gray-500 lg:block hidden'
          />
        </div>

        <div className='w-full p-6 my-10 bg-gray-100 border-2 border-gray-200 shadow-md rounded-2xl flex justify-between items-center animate-[insert_0.5s_ease-in-out]'>
          <div className='lg:w-1/2 w-full lg:mr-4 mr-0'>
            <h1 className='text-gray-800 text-3xl font-semibold mb-4'>Movie Website</h1>
            <p className='text-lg mb-4'>Su questo sito puoi trovare tutti gli ultimi film in uscita e una breve descrizione della loro trama!</p>
            <h4 className='text-xl font-semibold mb-8'>HTML, CSS, Javascript.</h4>
            <Link
              to="/movie-app"
              className='py-3 px-6 bg-indigo-600 text-white text-lg hover:bg-indigo-700 rounded'
            >Vai al progetto</Link>
          </div>
          <img
            src={movie}
            alt=""
            className='w-1/2 shadow-lg shadow-gray-500 lg:block hidden'
          />
        </div>

        <div className='w-full p-6 my-10 bg-gray-100 border-2 border-gray-200 shadow-md rounded-2xl flex justify-between items-center animate-[insert_0.5s_ease-in-out]'>
          <div className='lg:w-1/2 w-full lg:mr-4 mr-0'>
            <h1 className='text-gray-800 text-3xl font-semibold mb-4'>Generatore Password</h1>
            <p className='text-lg mb-4'>Questa applicazione ti aiuterà ad ottenere delle password random. Seleziona la quantità e la tipologia di caratteri che vuoi che la tua password abbia e generala in automatico!</p>
            <h4 className='text-xl font-semibold mb-8'>HTML, CSS, Javascript.</h4>
            <Link
              to="/password-app"
              className='py-3 px-6 bg-indigo-600 text-white text-lg hover:bg-indigo-700 rounded'
            >Vai al progetto</Link>
          </div>
          <img
            src={password}
            alt=""
            className='w-1/2 shadow-lg shadow-gray-500 lg:block hidden'
          />
        </div>
    </div>
  )
}

export default Projects