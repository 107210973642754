import python from '../../images/python.png'
import MenuPython from '../../components/MenuPython'

const python_introduzione = () => {
  return (
    <div className="lg:w-[1100px] sm:w-[100vw] min-h-[100vh] sm:mx-auto mx-4 flex flex-col items-center my-20 bg-gray-200 p-8 rounded-lg">
    <img src={python} alt="" className='w-[200px]' />
    <div className='grid grid-cols-4 w-full mt-6'>
        <div className='lg:col-span-3 col-span-4 lg:mr-4'>
            <h1 className='text-3xl text-indigo-700 font-semibold'>Che cos'è python</h1>
            <p className='mb-4'>
                Creiamo una nuova cartella e la chiamiamo <span className='p-1 bg-gray-300 text-indigo-600'>helloworld</span>. Apriamo con il nostro IDE (consiglio VS code) la cartella appena creata. 
                <br />
                All’interno della cartella creiamo un nuovo file <span className='p-1 bg-gray-300 text-indigo-600'>app.py</span> ed al suo interno scriviamo il seguente codice e poi salviamo:
            </p>
            <div className='w-full py-3 px-6 bg-slate-900 text-white mb-4'>
                print('Hello, World!')
            </div>
            <p className='mb-4'>
                Il print() è una funzione integrata che manda a schermo un messaggio. In questo esempio, sarà mostrato il messaggio <span className='p-1 bg-gray-300 text-indigo-600'>Hello, World!</span>.
            </p>

            <h2 className='font-bold'>Che cos’è una funzione?</h2>
            <p className="mb-4">
                Una funzione è un insieme di istruzioni che serve per eseguire un compito o dei calcoli e può essere riutilizzata. Ad una funzione possono essere passati uno o più valori e possono restituire un risultato al termine della loro esecuzione.
            </p>

            <p className="mb-4">
                Nell’esempio sopra riportato, print() è una funzione. Accetta una stringa e la mostra a schermo.
            </p>

            <p className="mb-4">
                Python ha molte funzioni integrate come print().
            </p>

            <p className="mb-4">
                Python permette di definire una funzione, ma questo argomento sarà trattato più avanti.
            </p>

            <h2 className='font-bold'>Come eseguite il programma “Hello World”</h2>
            <p className="mb-4">
                Per eseguire il <span className='p-1 bg-gray-300 text-indigo-600'>app.py</span>, bisogna lanciare il Command Prompt su Window o Terminal su macOS e Linux.
            </p>

            <p className="mb-4">
                Poi navighiamo fino alla cartella <span className='p-1 bg-gray-300 text-indigo-600'>helloworld</span> ed eseguiamo il seguente comando:
                <br />
                python app.py
            </p>

            <p className="mb-4">
                Poi navighiamo fino alla cartella <span className='p-1 bg-gray-300 text-indigo-600'>helloworld</span> ed eseguiamo il seguente comando:
                <br />
                python app.py
            </p>

            <p className="mb-4">
                Se usi macOS o Linux, invece usa il comando python3: 
                <br />
                python3 app.py
            </p>

            <p className="mb-4">
                Se tutto è andato bene, vedrai a schermo il seguente messaggio:
                <br />
                Hello, World!
            </p>

            <p className="mb-4">
                Se usi VS Code, puoi lanciare il terminal direttamente dall’IDE andato nel menu <strong>Teminal</strong> e cliccando su <strong>New Terminal</strong>.
            </p>
        </div>
        <MenuPython />
    </div>
</div>
  )
}

export default python_introduzione