import CourseCard from "../components/CourseCard"

import pythonImg from "../images/python.png"
import reactImg from "../images/react.png"

const Articles = () => {
  const articles = [
    {
      title: "Guida Python",
      img: pythonImg,
      url: "/article/python/1"
    },
    {
      title: "Guida React",
      img: reactImg,
      url: "/article/react/1"
    },
  ]

  return (
    <div className='bg-indigo-900 min-h-[85vh] lg:w-[1100px] sm:w-[100vw] lg:mx-auto sm:mx-4'>
        <h1 className='mt-20 text-white text-6xl animate-[insert_0.5s_ease-in-out]'>Articoli</h1>
        <div className="mt-20 w-full flex flex-wrap lg:justify-start justify-center">
            {articles.map(article => <CourseCard title={article.title} img={article.img} url={article.url} />)}
        </div>
    </div>
  )
}

export default Articles