import { Link } from "react-router-dom"
import Form from "../components/Form"

import django from '../images/django.png'
import laravel from '../images/laravel.png'
import mongodb from '../images/mongodb.png'
import mysql from '../images/mysql.png'
import node from '../images/node.png'
import react from '../images/react.png'
import python from '../images/python.png'
import vue from '../images/vue.png'

const Home = () => {
    return (
    <>
        <div className="h-[100vh] bg-gray-300">
            <div className="lg:w-[1200px] sm:w-full h-full flex flex-col justify-center sm:mx-auto mx-4 relative"> 
                <h1 className="text-gray-600 sm:text-4xl text-2xl mb-4 animate-[insert_0.5s_ease-in-out] z-10">Ciao! Benvenuto sul mio sito.</h1>
                <h1 className="text-gray-600 sm:text-6xl text-3xl mb-4 animate-[insert_0.5s_ease-in-out] z-10">Sono <span className="text-indigo-600">Riccardo Vercellesi</span>,</h1>
                <h1 className="text-gray-600 sm:text-4xl text-2xl mb-6 animate-[insert_0.5s_ease-in-out] z-10">Full Stack Web Developer.</h1>
                <p className="text-gray-600 sm:text-lg text-md mb-12 animate-[insert_0.5s_ease-in-out] z-10 lg:w-1/2 w-full">
                    All'interno del mio sito puoi trovare informazioni su di me, sulle mie capacità e i mie progetti.
                    <br />
                    Per saperne di più su di me clicca sul bottone qui sotto e per qualsiasi informazione contattami!
                </p>
                <Link to="/about" className="w-fit relative inline-flex items-center justify-center p-0.5 mb-2 mr-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-purple-600 to-blue-500 group-hover:from-purple-600 group-hover:to-blue-500 hover:text-white dark:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800">
                    <span className="relative px-5 py-2.5 transition-all ease-in duration-75 bg-white dark:bg-indigo-600 rounded-md group-hover:bg-opacity-0 text-xl">
                        Su di me
                    </span>
                </Link>
                <svg width="700" height="600" viewBox="0 0 863 827" fill="none" xmlns="http://www.w3.org/2000/svg" className="absolute top-[50%] right-0 translate-y-[-50%] lg:block hidden">
                    <g clip-path="url(#clip0_0_1)">
                    <path d="M740.085 309.387C695.168 132.765 539.968 5.26939 357.796 0.15564C261.488 -2.54787 160.144 29.3934 86.3586 141.168C-45.5411 340.976 94.4642 479.05 192.072 544.66C239.229 576.421 281.719 614.612 318.313 658.125C384.244 736.363 511.08 833.584 662.137 681.253C771.622 570.843 770.003 427.034 740.085 309.387Z" fill="#F2F2F2"/>
                    <path d="M862 702.714C862 746.069 672.811 826.714 434.5 826.714C196.189 826.714 0 751.069 0 707.714C0 664.36 196.189 669.714 434.5 669.714C672.811 669.714 862 659.36 862 702.714Z" fill="#3F3D56"/>
                    <path opacity="0.1" d="M862 702.714C862 746.069 672.811 826.714 434.5 826.714C196.189 826.714 0 751.069 0 707.714C0 664.36 196.189 669.714 434.5 669.714C672.811 669.714 862 659.36 862 702.714Z" fill="black"/>
                    <path d="M431.5 785.714C669.811 785.714 863 750.569 863 707.214C863 663.86 669.811 628.714 431.5 628.714C193.189 628.714 0 663.86 0 707.214C0 750.569 193.189 785.714 431.5 785.714Z" fill="#3F3D56"/>
                    <path d="M594.859 440.238V650.182L629.976 707.413L632.649 711.766H771.212L774.011 440.238H594.859Z" fill="#2F2E41"/>
                    <path opacity="0.1" d="M594.859 440.238V650.182L629.976 707.413L632.131 440.238H594.859Z" fill="black"/>
                    <path d="M260.348 434.639V644.583L225.231 701.814L222.558 706.167H83.994L81.195 434.639H260.348Z" fill="#2F2E41"/>
                    <path opacity="0.1" d="M260.348 434.639V644.583L225.231 701.814L223.075 434.639H260.348Z" fill="black"/>
                    <path d="M816 430.44V443.037H63V424.842L132.981 388.452H754.416L816 430.44Z" fill="#2F2E41"/>
                    <path opacity="0.1" d="M816 430.44V443.037H63V424.842L816 430.44Z" fill="black"/>
                    <path opacity="0.1" d="M170.771 493.424H137.18L123.184 483.626H186.167L170.771 493.424Z" fill="black"/>
                    <path opacity="0.1" d="M170.771 534.013H137.18L123.184 524.215H186.167L170.771 534.013Z" fill="black"/>
                    <path opacity="0.1" d="M170.771 591.398H137.18L123.184 581.6H186.167L170.771 591.398Z" fill="black"/>
                    <path opacity="0.1" d="M170.771 648.782H137.18L123.184 638.985H186.167L170.771 648.782Z" fill="black"/>
                    <path opacity="0.1" d="M684.435 499.022H718.026L732.022 489.225H669.039L684.435 499.022Z" fill="black"/>
                    <path opacity="0.1" d="M684.435 539.611H718.026L732.022 529.814H669.039L684.435 539.611Z" fill="black"/>
                    <path opacity="0.1" d="M684.435 596.996H718.026L732.022 587.199H669.039L684.435 596.996Z" fill="black"/>
                    <path opacity="0.1" d="M684.435 654.381H718.026L732.022 644.583H669.039L684.435 654.381Z" fill="black"/>
                    <path d="M532.516 353.427L527.847 383.234C527.847 383.234 504.863 395.444 521.024 395.804C537.184 396.163 613.319 395.804 613.319 395.804C613.319 395.804 628.043 395.804 604.7 382.875L600.031 351.631L532.516 353.427Z" fill="#3F3D56"/>
                    <path opacity="0.1" d="M517.773 395.48C520.911 392.942 524.285 390.71 527.847 388.812L532.516 359.004L600.031 359.068L604.7 388.452C608.536 390.432 612.152 392.812 615.489 395.551C618.487 394.867 621.763 392.325 604.7 382.875L600.031 351.631L532.516 353.427L527.847 383.234C527.847 383.234 508.534 393.496 517.773 395.48Z" fill="black"/>
                    <path d="M707.31 145.672H424.519C418.613 145.672 413.824 150.46 413.824 156.367V346.862C413.824 352.768 418.613 357.557 424.519 357.557H707.31C713.216 357.557 718.005 352.768 718.005 346.862V156.367C718.005 150.46 713.216 145.672 707.31 145.672Z" fill="#3F3D56"/>
                    <path d="M707.77 156.984H424.059V315.718H707.77V156.984Z" fill="#F2F2F2"/>
                    <path d="M565.914 153.752C566.906 153.752 567.71 152.948 567.71 151.957C567.71 150.965 566.906 150.161 565.914 150.161C564.923 150.161 564.119 150.965 564.119 151.957C564.119 152.948 564.923 153.752 565.914 153.752Z" fill="#F2F2F2"/>
                    <path d="M718.005 328.288V346.862C718.005 348.266 717.728 349.657 717.191 350.955C716.654 352.252 715.866 353.431 714.873 354.424C713.879 355.418 712.7 356.205 711.403 356.743C710.105 357.28 708.714 357.557 707.31 357.557H424.519C423.115 357.557 421.724 357.28 420.426 356.743C419.129 356.205 417.949 355.418 416.956 354.424C415.963 353.431 415.175 352.252 414.638 350.955C414.101 349.657 413.824 348.266 413.824 346.862V328.288H718.005Z" fill="#3F3D56"/>
                    <path d="M565.914 349.835C569.683 349.835 572.738 346.78 572.738 343.012C572.738 339.243 569.683 336.189 565.914 336.189C562.146 336.189 559.091 339.243 559.091 343.012C559.091 346.78 562.146 349.835 565.914 349.835Z" fill="#F2F2F2"/>
                    <path d="M555.281 415.196V418.788H362.071V415.915L362.337 415.196L367.099 402.268H551.331L555.281 415.196Z" fill="#3F3D56"/>
                    <path d="M629.635 411.681C629.283 413.185 627.954 414.773 624.952 416.274C614.178 421.661 592.272 414.837 592.272 414.837C592.272 414.837 575.393 411.964 575.393 404.423C575.866 404.098 576.361 403.804 576.872 403.543C581.402 401.146 596.42 395.233 623.05 403.793C625.012 404.409 626.764 405.557 628.112 407.11C628.709 407.697 629.158 408.418 629.423 409.212C629.688 410.007 629.761 410.853 629.635 411.681Z" fill="#3F3D56"/>
                    <path opacity="0.1" d="M629.635 411.681C616.448 416.733 604.694 417.111 592.631 408.732C586.547 404.509 581.02 403.464 576.872 403.543C581.402 401.146 596.42 395.233 623.05 403.793C625.012 404.409 626.764 405.557 628.112 407.11C628.709 407.697 629.158 408.418 629.423 409.212C629.688 410.007 629.761 410.853 629.635 411.681Z" fill="black"/>
                    <path d="M613.101 408.732C615.679 408.732 617.77 408.089 617.77 407.296C617.77 406.502 615.679 405.859 613.101 405.859C610.522 405.859 608.432 406.502 608.432 407.296C608.432 408.089 610.522 408.732 613.101 408.732Z" fill="#F2F2F2"/>
                    <path opacity="0.1" d="M555.281 415.196V418.788H362.071V415.915L362.337 415.196H555.281Z" fill="black"/>
                    <path d="M665.694 231.951C664.169 230.427 644.606 205.783 641.303 201.718C637.747 197.653 636.222 198.415 633.936 198.669C631.649 198.923 607.005 203.242 604.21 203.496C601.415 204.004 599.637 205.02 601.415 207.561C602.94 209.848 619.2 232.713 622.757 238.049L557.97 253.547L506.65 167.419C504.617 164.37 504.109 163.354 499.536 163.608C494.963 163.862 459.14 166.911 456.599 166.911C454.058 167.165 451.264 168.181 453.804 174.279C456.345 180.376 496.995 267.774 498.011 270.315C499.028 272.856 502.076 276.921 508.936 275.396C516.05 273.618 540.44 267.266 553.905 263.709C561.019 276.412 575.247 302.327 578.042 306.138C581.598 311.219 584.139 310.203 589.474 308.679C593.793 307.408 656.039 285.051 658.834 283.78C661.629 282.51 663.407 281.748 661.375 278.953C659.85 276.921 643.59 254.817 634.952 243.384C640.795 241.86 661.883 236.27 664.169 235.508C666.71 234.746 667.218 233.476 665.694 231.951ZM548.062 256.087C547.3 256.341 510.969 264.98 509.19 265.488C507.158 265.996 507.158 265.742 507.158 264.98C506.65 264.217 463.967 175.803 463.205 174.787C462.696 173.771 462.696 172.754 463.205 172.754C463.713 172.754 497.503 169.706 498.519 169.706C499.79 169.706 499.536 169.96 500.044 170.722C500.044 170.722 547.554 252.785 548.316 254.055C549.332 255.325 548.824 255.833 548.062 256.087ZM650.196 275.142C650.704 276.158 651.466 276.667 649.434 277.175C647.655 277.937 588.204 298.008 586.934 298.516C585.663 299.024 584.901 299.278 583.377 296.992C581.852 294.705 562.544 261.423 562.544 261.423L625.806 244.909C627.33 244.4 627.838 244.146 628.854 245.671C629.871 247.449 649.688 274.38 650.196 275.142ZM654.261 230.427C652.736 230.681 629.616 236.524 629.616 236.524L610.562 210.61C610.054 209.848 609.545 209.086 610.816 208.831C612.086 208.577 633.682 204.766 634.698 204.512C635.714 204.258 636.476 204.004 637.747 205.783C639.017 207.307 655.277 228.14 656.039 228.903C656.801 229.665 655.785 230.173 654.261 230.427Z" fill="#FB503B"/>
                    <path d="M394.639 194.141C415.762 194.141 432.886 177.018 432.886 155.895C432.886 134.771 415.762 117.648 394.639 117.648C373.516 117.648 356.392 134.771 356.392 155.895C356.392 177.018 373.516 194.141 394.639 194.141Z" fill="#FFB9B9"/>
                    <path d="M366.457 175.018V235.407H428.86C428.86 235.407 420.808 185.083 424.834 169.985L366.457 175.018Z" fill="#FFB9B9"/>
                    <path d="M490.256 256.544C490.256 256.544 430.873 227.355 430.873 225.342C430.873 223.329 424.834 210.245 420.808 210.245C416.782 210.245 382.561 199.174 363.438 215.278L361.425 228.362L287.951 276.674L308.081 350.147C308.081 350.147 321.165 364.238 317.139 374.303C313.113 384.368 320.159 435.699 320.159 435.699L478.178 430.667V362.225C478.178 362.225 483.21 346.121 486.23 341.089C489.249 336.057 486.23 320.959 486.23 320.959L510.386 290.764C510.386 290.764 507.366 262.583 490.256 256.544Z" fill="#D0CDE1"/>
                    <path opacity="0.1" d="M290.467 301.333L294.996 276.674C294.996 276.674 268.828 390.407 271.847 403.491C274.867 416.576 297.009 436.706 300.029 437.712C303.048 438.719 331.23 351.154 331.23 351.154L290.467 301.333Z" fill="black"/>
                    <path d="M305.061 273.654L287.951 276.673C287.951 276.673 261.782 390.407 264.802 403.491C267.821 416.576 289.964 436.706 292.983 437.712C296.003 438.719 324.185 351.154 324.185 351.154L305.061 273.654Z" fill="#D0CDE1"/>
                    <path opacity="0.1" d="M495.791 307.371L504.347 290.764C504.347 290.764 516.425 404.498 508.373 415.569C500.321 426.641 468.47 430.784 468.47 430.784L468.113 348.134L495.791 307.371Z" fill="black"/>
                    <path d="M497.301 277.68L510.386 290.764C510.386 290.764 522.463 404.498 514.412 415.569C506.36 426.641 474.509 430.784 474.509 430.784L474.152 348.134L497.301 277.68Z" fill="#D0CDE1"/>
                    <path d="M302.042 450.796C302.042 450.796 272.854 439.725 267.821 460.861C262.789 481.998 279.899 576.608 279.899 576.608C279.899 576.608 290.97 648.069 298.016 650.082C305.061 652.095 336.263 653.101 341.295 644.043C346.327 634.984 326.198 542.387 326.198 542.387L442.951 540.374C442.951 540.374 435.905 620.894 431.879 627.939C427.853 634.984 427.853 659.14 431.879 659.14C435.905 659.14 480.191 657.127 483.21 651.088C486.23 645.049 503.34 509.173 503.34 509.173C503.34 509.173 517.431 461.868 501.327 464.887C485.223 467.907 302.042 450.796 302.042 450.796Z" fill="#3F3D56"/>
                    <path d="M474.152 647.062C474.152 647.062 484.217 640.017 489.249 647.062C494.282 654.108 496.295 662.16 474.152 674.238C452.009 686.316 434.899 683.296 434.899 681.283V652.095L474.152 647.062Z" fill="#2F2E41"/>
                    <path d="M300.029 644.043C300.029 644.043 289.964 636.997 284.931 644.043C279.899 651.088 277.886 659.14 300.029 671.218C322.172 683.296 339.282 680.277 339.282 678.264V649.075L300.029 644.043Z" fill="#2F2E41"/>
                    <path d="M394.887 107.669C391.078 107.537 387.275 108.079 383.654 109.27C380.846 110.399 378.164 111.818 375.651 113.504L358.765 124.005C357.332 124.625 356.114 125.656 355.266 126.968C354.418 128.279 353.977 129.813 353.999 131.375L351.889 147.176C351.357 151.164 350.866 155.429 352.651 159.034C354.141 162.044 356.982 164.103 359.319 166.515C363.058 170.39 365.574 175.28 366.555 180.576C367.763 187.173 366.99 195.118 372.211 199.328C375.038 201.608 378.891 202.039 382.517 202.251C392.431 202.83 402.377 202.447 412.217 201.108C413.782 200.991 415.307 200.564 416.705 199.851C418.48 198.801 419.597 196.948 420.642 195.169C424.412 188.75 428.299 181.92 428.258 174.475C428.238 170.792 427.295 166.837 429.064 163.606C429.998 162.232 431.013 160.915 432.101 159.661C434.291 156.584 434.717 152.631 435.074 148.871C436.214 136.862 435.022 127.724 426.172 119.265C417.91 111.368 406.155 107.742 394.887 107.669Z" fill="#2F2E41"/>
                    <path d="M300.937 569.703L267.346 740.458H284.141L316.333 572.503L300.937 569.703Z" fill="#2F2E41"/>
                    <path d="M408.708 583.7L429.703 779.648H443.699L431.102 578.101L408.708 583.7Z" fill="#2F2E41"/>
                    <path d="M466.093 578.101L495.485 708.267H509.481L481.489 575.302L466.093 578.101Z" fill="#2F2E41"/>
                    <path d="M354.123 578.101L349.924 681.674H361.121L369.519 578.101H354.123Z" fill="#2F2E41"/>
                    <path d="M277.143 551.508C277.143 551.508 254.749 401.748 317.732 397.549C380.716 393.35 470.292 397.549 470.292 397.549C470.292 397.549 522.078 393.35 506.682 551.508C506.682 551.508 520.678 597.696 394.712 593.497C268.745 589.298 277.143 551.508 277.143 551.508Z" fill="#6C63FF"/>
                    <path d="M851 612.665C851 638.78 835.475 647.899 816.324 647.899C797.172 647.899 781.647 638.78 781.647 612.665C781.647 586.55 816.324 553.327 816.324 553.327C816.324 553.327 851 586.55 851 612.665Z" fill="#6C63FF"/>
                    <path d="M815.416 622.048L830.195 595.009L815.471 618.619L815.631 608.792L825.817 589.229L815.673 606.191L815.96 588.516L826.868 572.942L816.005 585.737L816.185 553.327L815.106 594.394L804.06 577.487L814.972 597.859L813.939 617.599L813.908 617.075L801.123 599.212L813.869 618.926L813.74 621.395L813.717 621.432L813.728 621.635L811.106 647.977H814.609L815.029 645.849L827.744 626.182L815.06 643.904L815.416 622.048Z" fill="#3F3D56"/>
                    <path d="M838.47 704.549H797.129L786.445 644.163H846.831L838.47 704.549Z" fill="#2F2E41"/>
                    <path d="M231 296.3H145V397.128H231V296.3Z" fill="#3F3D56"/>
                    <path d="M217.655 311.946H158.345V381.482H217.655V311.946Z" fill="#F2F2F2"/>
                    <path d="M193.944 326.714L187.921 337.146L181.898 326.714H161.843L187.921 371.885L214 326.714H193.944Z" fill="#4DBA87"/>
                    <path d="M193.944 326.714L187.921 337.146L181.898 326.714H172.274L187.921 353.815L203.568 326.714H193.944Z" fill="#435466"/>
                    </g>
                    <defs>
                    <clipPath id="clip0_0_1">
                    <rect width="863" height="826.714" fill="white"/>
                    </clipPath>
                    </defs>
                </svg>
            </div>
        </div>

        <div className="w-full bg-neutral-900 flex flex-col items-center justify-center sm:py-20 py-4">
            <h1 className="text-gray-200 sm:text-6xl text-4xl sm:mb-14 mb-4">Di cosa mi occupo</h1>
            <div className="lg:w-[1100px] sm:w-[100vw] flex sm:flex-row flex-col items-center justify-between sm:mx-auto mx-4 p-10 bg-slate-900 mb-8 rounded-2xl group border-2 border-indigo-600">
            <svg viewBox="0 0 1099 633" fill="none" xmlns="http://www.w3.org/2000/svg" className="mr-4 lg:500px sm:400px sm:block hidden">
                <g clip-path="url(#clip0_4_2)">
                <path d="M549.375 632.37C852.786 632.37 1098.75 618.224 1098.75 600.774C1098.75 583.323 852.786 569.177 549.375 569.177C245.964 569.177 0 583.323 0 600.774C0 618.224 245.964 632.37 549.375 632.37Z" fill="#F2F2F2"/>
                <path d="M1000.29 0H174.967V26.257H1000.29V0Z" fill="#F2F2F2"/>
                <path d="M197.053 19.5369C200.806 19.5369 203.848 16.4945 203.848 12.7415C203.848 8.98845 200.806 5.94601 197.053 5.94601C193.3 5.94601 190.257 8.98845 190.257 12.7415C190.257 16.4945 193.3 19.5369 197.053 19.5369Z" fill="#6C63FF"/>
                <path d="M219.138 19.5369C222.891 19.5369 225.933 16.4945 225.933 12.7415C225.933 8.98845 222.891 5.94601 219.138 5.94601C215.385 5.94601 212.342 8.98845 212.342 12.7415C212.342 16.4945 215.385 19.5369 219.138 19.5369Z" fill="#6C63FF"/>
                <path d="M241.223 19.5369C244.976 19.5369 248.019 16.4945 248.019 12.7415C248.019 8.98845 244.976 5.94601 241.223 5.94601C237.47 5.94601 234.428 8.98845 234.428 12.7415C234.428 16.4945 237.47 19.5369 241.223 19.5369Z" fill="#6C63FF"/>
                <path d="M175.552 415.277L174.967 415.779V592.903H1000.28V281.779L990.449 282.525L175.552 415.277Z" fill="#3F3D56"/>
                <path d="M464.999 175.641H234.529V184.934H464.999V175.641Z" fill="#3F3D56"/>
                <path d="M464.999 196.086H234.529V205.379H464.999V196.086Z" fill="#3F3D56"/>
                <path d="M312.591 216.531H234.529V225.824H312.591V216.531Z" fill="#3F3D56"/>
                <path d="M934.999 433.641H704.529V442.934H934.999V433.641Z" fill="white"/>
                <path d="M934.999 454.086H704.529V463.379H934.999V454.086Z" fill="white"/>
                <path d="M782.591 474.531H704.529V483.824H782.591V474.531Z" fill="white"/>
                <path d="M561.336 584.335C566.342 584.335 570.401 580.276 570.401 575.269C570.401 570.262 566.342 566.203 561.336 566.203C556.329 566.203 552.27 570.262 552.27 575.269C552.27 580.276 556.329 584.335 561.336 584.335Z" fill="#F2F2F2"/>
                <path d="M587.626 585.242C592.633 585.242 596.692 581.183 596.692 576.176C596.692 571.169 592.633 567.11 587.626 567.11C582.619 567.11 578.56 571.169 578.56 576.176C578.56 581.183 582.619 585.242 587.626 585.242Z" fill="#F2F2F2"/>
                <path d="M613.917 586.148C618.924 586.148 622.983 582.089 622.983 577.082C622.983 572.075 618.924 568.017 613.917 568.017C608.91 568.017 604.851 572.075 604.851 577.082C604.851 582.089 608.91 586.148 613.917 586.148Z" fill="#F2F2F2"/>
                <path d="M587.709 583.428C591.715 583.428 594.962 580.181 594.962 576.176C594.962 572.17 591.715 568.923 587.709 568.923C583.704 568.923 580.457 572.17 580.457 576.176C580.457 580.181 583.704 583.428 587.709 583.428Z" fill="#3F3D56"/>
                <path d="M388.591 282.014H310.529V299.824H388.591V282.014Z" fill="#3F3D56"/>
                <path d="M852.591 523.014H774.529V540.824H852.591V523.014Z" fill="white"/>
                <path opacity="0.3" d="M350 345.014C379.823 345.014 404 320.837 404 291.014C404 261.19 379.823 237.014 350 237.014C320.177 237.014 296 261.19 296 291.014C296 320.837 320.177 345.014 350 345.014Z" fill="#6C63FF"/>
                <path opacity="0.3" d="M350 327.014C369.882 327.014 386 310.896 386 291.014C386 271.131 369.882 255.014 350 255.014C330.118 255.014 314 271.131 314 291.014C314 310.896 330.118 327.014 350 327.014Z" fill="#6C63FF"/>
                <path d="M350 316.014C363.807 316.014 375 304.821 375 291.014C375 277.207 363.807 266.014 350 266.014C336.193 266.014 325 277.207 325 291.014C325 304.821 336.193 316.014 350 316.014Z" fill="#6C63FF"/>
                <path opacity="0.3" d="M804.375 211.185C834.198 211.185 858.375 187.009 858.375 157.185C858.375 127.362 834.198 103.185 804.375 103.185C774.552 103.185 750.375 127.362 750.375 157.185C750.375 187.009 774.552 211.185 804.375 211.185Z" fill="#6C63FF"/>
                <path opacity="0.3" d="M804.375 193.185C824.257 193.185 840.375 177.067 840.375 157.185C840.375 137.303 824.257 121.185 804.375 121.185C784.493 121.185 768.375 137.303 768.375 157.185C768.375 177.067 784.493 193.185 804.375 193.185Z" fill="#6C63FF"/>
                <path d="M804.375 182.185C818.182 182.185 829.375 170.992 829.375 157.185C829.375 143.378 818.182 132.185 804.375 132.185C790.568 132.185 779.375 143.378 779.375 157.185C779.375 170.992 790.568 182.185 804.375 182.185Z" fill="#6C63FF"/>
                <path opacity="0.3" d="M814 586.014C843.823 586.014 868 561.837 868 532.014C868 502.19 843.823 478.014 814 478.014C784.177 478.014 760 502.19 760 532.014C760 561.837 784.177 586.014 814 586.014Z" fill="#6C63FF"/>
                <path opacity="0.3" d="M814 568.014C833.882 568.014 850 551.896 850 532.014C850 512.131 833.882 496.014 814 496.014C794.118 496.014 778 512.131 778 532.014C778 551.896 794.118 568.014 814 568.014Z" fill="#6C63FF"/>
                <path d="M814 557.014C827.807 557.014 839 545.821 839 532.014C839 518.207 827.807 507.014 814 507.014C800.193 507.014 789 518.207 789 532.014C789 545.821 800.193 557.014 814 557.014Z" fill="#6C63FF"/>
                <path d="M89.9221 109.361L112.148 110.223L113.256 81.6473L91.0303 80.7854L89.9221 109.361Z" fill="#6C63FF"/>
                <path d="M191.29 180.864C191.29 180.864 222.069 166.158 221.576 178.859C221.084 191.559 190.551 199.915 190.551 199.915L191.29 180.864Z" fill="#FFB9B9"/>
                <path d="M174.403 288.325C174.403 288.325 188.924 323.866 194.29 349.513C199.655 375.16 207.826 410.456 197.315 435.487C186.805 460.518 156.924 534.086 156.616 542.024C156.308 549.962 159.175 558.022 152.825 557.776C146.475 557.53 111.918 546.65 110.515 541.826C109.113 537.002 122.305 524.794 122.305 524.794L154.883 422.712L120.893 356.207L96.3767 455.422L91.5749 579.25C91.5749 579.25 68.008 571.976 64.5866 578.203C64.5866 578.203 54.0279 563.485 52.6866 557.073C51.3453 550.661 57.6731 428.482 57.6731 428.482C57.6731 428.482 36.0408 289.319 53.5038 289.996C70.9667 290.673 139.367 248.808 174.403 288.325Z" fill="#2F2E41"/>
                <path d="M153.071 551.426C153.071 551.426 169.18 587.029 170.706 588.678C172.232 590.327 182.729 606.634 174.791 606.326C166.854 606.018 148.111 597.342 134.315 584.087C120.52 570.833 102.331 547.868 103.98 546.342C105.63 544.816 116.927 540.485 116.927 540.485L153.071 551.426Z" fill="#2F2E41"/>
                <path d="M88.7076 571.189C88.7076 571.189 89.6795 587.126 92.6084 593.599C95.5372 600.073 95.1678 609.598 87.2301 609.29C79.2924 608.982 58.7775 605.007 58.7775 605.007C58.7775 605.007 57.5593 595.42 59.2084 593.894C60.8575 592.368 67.8849 575.151 64.8945 570.266C61.9041 565.38 88.7076 571.189 88.7076 571.189Z" fill="#2F2E41"/>
                <path d="M76.9644 54.0061C76.9644 54.0061 60.0424 80.3787 53.446 86.4827C46.8495 92.5866 65.4076 106.026 65.4076 106.026L98.6229 110.494C98.6229 110.494 98.205 80.2686 99.9157 77.1551C101.626 74.0416 76.9644 54.0061 76.9644 54.0061Z" fill="#FFB9B9"/>
                <path d="M105.835 88.5142C105.835 88.5142 98.9923 100.968 92.6421 100.722C86.2919 100.476 53.446 86.4827 52.0431 81.6585C50.6402 76.8343 41.1635 116.215 41.1635 116.215L121.535 257.656L141.14 244.107L131.64 161.062L123.839 116.241L105.835 88.5142Z" fill="#6C63FF"/>
                <path d="M49.0786 215.098C52.2846 221.804 54.287 228.798 54.0093 235.958C53.997 236.276 53.9847 236.593 53.9565 236.91C53.1959 251.603 47.7831 268.596 45.8411 280.954C44.4321 289.819 44.8331 296.29 50.0209 297.811C62.5981 301.478 48.1871 304.099 96.8468 320.296C145.507 336.492 153.998 322.512 154.122 319.337C154.245 316.162 147.23 292.041 148.88 290.515C150.529 288.989 171.954 310.489 178.427 307.56C184.901 304.631 179.166 288.509 179.166 288.509C179.166 288.509 164.583 254.555 164.891 246.618C165.199 238.68 142.802 201.243 142.802 201.243L127.568 102.076C127.568 102.076 121.71 89.1298 113.834 87.2345C105.958 85.3391 99.4232 89.8555 99.4232 89.8555L112.603 118.985L126.508 170.402L124.182 189.391C124.182 189.391 108.012 155.376 97.3917 142.244C86.7713 129.113 64.1279 98.0264 64.1279 98.0264C64.1279 98.0264 58.8414 80.189 62.8322 74.8585C66.8235 69.5122 46.1855 68.7119 37.3859 90.6297C32.7221 102.246 25.1501 119.569 20.4067 135.698C16.1798 149.986 14.1832 163.344 18.3708 170.979C24.771 182.643 40.8708 197.974 49.0786 215.098Z" fill="#575A89"/>
                <path opacity="0.1" d="M18.3708 170.979C24.771 182.643 40.8708 197.974 49.0786 215.098C51.2224 207.374 53.8377 199.383 53.8377 199.383C53.8377 199.383 63.8685 145.714 34.1977 131.844C29.2814 131.369 24.3645 132.743 20.4066 135.698C16.1798 149.986 14.1832 163.344 18.3708 170.979Z" fill="black"/>
                <path d="M136.157 167.597L161.311 174.932L196.237 176.286L199.83 206.634L142.432 210.768C142.432 210.768 128.096 170.464 136.157 167.597Z" fill="#575A89"/>
                <path d="M95.139 269.636C95.139 269.636 128.791 275.219 121.11 285.346C113.429 295.473 83.618 284.826 83.618 284.826L95.139 269.636Z" fill="#FFB9B9"/>
                <path opacity="0.1" d="M53.9565 236.91C53.1959 251.603 47.7831 268.596 45.8412 280.954C56.5613 291.1 66.7555 300.05 70.5974 300.199C78.5351 300.506 91.297 299.411 95.9981 301.184C100.699 302.956 103.456 272.854 103.456 272.854C103.456 272.854 100.651 263.206 88.135 257.95C79.6872 254.395 63.494 244.752 53.9565 236.91Z" fill="black"/>
                <path d="M34.5671 122.319C34.5671 122.319 7.70193 118.097 9.15328 162.672C10.6046 207.246 6.32143 235.699 17.1264 244.067C27.9314 252.436 63.029 290.365 70.9667 290.673C78.9045 290.981 91.6664 289.886 96.3675 291.658C101.069 293.431 103.826 263.329 103.826 263.329C103.826 263.329 101.02 253.68 88.5044 248.425C75.9887 243.17 46.5025 224.537 46.7488 218.187C46.995 211.837 54.2071 189.858 54.2071 189.858C54.2071 189.858 64.2379 136.189 34.5671 122.319Z" fill="#575A89"/>
                <path d="M100.654 93.0568C119.958 93.0568 135.607 77.4082 135.607 58.1046C135.607 38.8011 119.958 23.1524 100.654 23.1524C81.3508 23.1524 65.7022 38.8011 65.7022 58.1046C65.7022 77.4082 81.3508 93.0568 100.654 93.0568Z" fill="#FFB9B9"/>
                <path d="M124.189 8.00793C125.032 8.73428 126.041 9.24091 127.127 9.4827C127.673 9.57991 128.234 9.45745 128.69 9.14205C129.145 8.82665 129.457 8.34392 129.558 7.79925C130.357 9.45766 131.26 11.2252 132.892 12.0761C134.525 12.9271 137.05 12.2136 137.285 10.3875C137.523 11.774 138.137 13.0687 139.059 14.1314C140.072 15.0973 141.909 15.341 142.793 14.2562C142.078 18.1637 142.519 22.1821 142.295 26.1481C142.07 30.1141 141.013 34.3186 138.016 36.9265C133.646 40.7298 127.075 39.7911 121.327 39.0706C120.458 38.8882 119.556 38.9375 118.712 39.2136C116.559 40.1306 116.657 43.1268 116.628 45.4661C116.452 49.5707 114.828 53.4802 112.043 56.5005C109.258 59.5208 105.493 61.4564 101.416 61.9636C99.1342 62.3157 96.8061 61.7476 94.9433 60.3839C93.3112 59.0006 92.4776 56.7459 90.6075 55.7067C87.5654 54.0163 83.8759 56.4816 81.6518 59.1583C79.4276 61.835 77.2356 65.1276 73.7901 65.6176C69.2332 66.2656 65.7624 61.6216 64.0596 57.3454C61.7103 51.352 60.5848 44.9489 60.7493 38.5136C60.9139 32.0783 62.3653 25.7411 65.018 19.8756C74.5329 -0.865423 107.036 -4.14604 124.189 8.00793Z" fill="#2F2E41"/>
                <path d="M963.222 563.77C975.532 584.762 1000.36 593.073 1000.36 593.073C1000.36 593.073 1005.23 567.349 992.918 546.356C980.608 525.364 955.781 517.053 955.781 517.053C955.781 517.053 950.913 542.777 963.222 563.77Z" fill="#F2F2F2"/>
                <path d="M970.904 556.443C992.192 568.233 1001.11 592.848 1001.11 592.848C1001.11 592.848 975.513 598.347 954.225 586.557C932.937 574.766 924.018 550.151 924.018 550.151C924.018 550.151 949.615 544.652 970.904 556.443Z" fill="#6C63FF"/>
                </g>
            </svg>
            <div>
                <h2 className="text-3xl text-indigo-600 mb-4 font-semibold">Frontend</h2>
                <p className="text-lg text-gray-300">
                    Sviluppo di siti web statici attraverso i linguaggi di formattazione HTML e CSS. 
                    Animazione e aggiunta di funzionalità attraverso il linguaggio Javascript. 
                    Creazione di single web application, tramite i framework React.js + Redux e framework Vue.js.
                </p>
            </div>
        </div>

        <div className="lg:w-[1100px] sm:w-[100vw] flex sm:flex-row flex-col items-center justify-between sm:mx-auto mx-4 p-10 bg-slate-900 mb-8 rounded-2xl group border-2 border-indigo-600">
            <div>
                <h2 className="text-3xl text-indigo-600 mb-4 text-right font-semibold">Backend</h2>
                <p className="text-lg text-gray-200">
                    Creazione di web server attraverso Node.js + Express e PHP, completi di autenticazione utente e operazioni CRUD. 
                    Sviluppo di applicazioni web tramite framework Laravel e Django.
                    Storage dei dati all'interno di database SQL e No-SQL.
                </p>
            </div>
            <svg viewBox="0 0 1099 633" fill="none" xmlns="http://www.w3.org/2000/svg" className="ml-6 lg:500px sm:400px sm:block hidden">
                <g clip-path="url(#clip0_0_1)">
                <path d="M292.609 426C410.246 426 505.609 330.637 505.609 213C505.609 95.3633 410.246 0 292.609 0C174.972 0 79.6091 95.3633 79.6091 213C79.6091 330.637 174.972 426 292.609 426Z" fill="#F2F2F2"/>
                <path d="M0 51.1424C0 128.64 48.6181 191.351 108.701 191.351Z" fill="#2F2E41"/>
                <path d="M108.701 191.351C108.701 112.982 162.956 49.567 230.004 49.567Z" fill="#6C63FF"/>
                <path d="M39.3843 58.1677C39.3843 131.782 70.3872 191.351 108.701 191.351Z" fill="#6C63FF"/>
                <path d="M108.701 191.351C108.701 91.2128 171.411 10.1827 248.909 10.1827Z" fill="#2F2E41"/>
                <path d="M85.8329 192.339C85.8329 192.339 101.248 191.864 105.894 188.556C110.54 185.248 129.607 181.298 130.76 186.603C131.912 191.909 153.927 212.991 136.522 213.132C119.118 213.272 96.0831 210.421 91.4462 207.596C86.8093 204.772 85.8329 192.339 85.8329 192.339Z" fill="#A8A8A8"/>
                <path opacity="0.2" d="M136.833 211.285C119.429 211.425 96.3938 208.574 91.7568 205.749C88.2256 203.598 86.8184 195.88 86.3476 192.319C86.0215 192.333 85.8329 192.339 85.8329 192.339C85.8329 192.339 86.8093 204.772 91.4463 207.596C96.0832 210.421 119.118 213.272 136.523 213.132C141.546 213.091 143.282 211.304 143.186 208.656C142.488 210.256 140.572 211.255 136.833 211.285Z" fill="black"/>
                <path d="M198.609 449.94C301.886 449.94 385.609 438.55 385.609 424.5C385.609 410.45 301.886 399.06 198.609 399.06C95.3319 399.06 11.6091 410.45 11.6091 424.5C11.6091 438.55 95.3319 449.94 198.609 449.94Z" fill="#3F3D56"/>
                <path opacity="0.1" d="M198.609 445.859C285.318 445.859 355.609 436.296 355.609 424.5C355.609 412.704 285.318 403.141 198.609 403.141C111.9 403.141 41.6091 412.704 41.6091 424.5C41.6091 436.296 111.9 445.859 198.609 445.859Z" fill="black"/>
                <path d="M836.609 699C992.906 699 1119.61 681.763 1119.61 660.5C1119.61 639.237 992.906 622 836.609 622C680.313 622 553.609 639.237 553.609 660.5C553.609 681.763 680.313 699 836.609 699Z" fill="#3F3D56"/>
                <path d="M458 629.31C549.596 653.156 725.663 598.207 745 536" stroke="#2F2E41" stroke-width="2" stroke-miterlimit="10"/>
                <path d="M310 261.737C310 261.737 473.437 208.985 483.494 378.964C493.552 548.942 348.973 597.297 462.122 631" stroke="#2F2E41" stroke-width="2" stroke-miterlimit="10"/>
                <path d="M778.705 563.24L770.827 613.535C770.827 613.535 732.045 634.138 759.314 634.744C786.582 635.35 915.047 634.744 915.047 634.744C915.047 634.744 939.892 634.744 900.504 612.929L892.626 560.21L778.705 563.24Z" fill="#2F2E41"/>
                <path opacity="0.1" d="M753.828 634.198C760.021 628.688 770.827 622.946 770.827 622.946L778.705 572.651L892.626 572.758L900.504 622.34C909.689 627.427 915.379 631.327 918.707 634.318C923.766 633.164 929.295 628.875 900.504 612.929L892.626 560.21L778.705 563.24L770.827 613.535C770.827 613.535 738.239 630.85 753.828 634.198Z" fill="black"/>
                <path d="M1073.64 212.689H596.479C586.512 212.689 578.433 220.768 578.433 230.734V552.163C578.433 562.129 586.512 570.208 596.479 570.208H1073.64C1083.61 570.208 1091.69 562.129 1091.69 552.163V230.734C1091.69 220.768 1083.61 212.689 1073.64 212.689Z" fill="#2F2E41"/>
                <path d="M1074.42 231.777H595.703V499.613H1074.42V231.777Z" fill="#3F3D56"/>
                <path d="M835.06 226.323C836.733 226.323 838.089 224.966 838.089 223.293C838.089 221.62 836.733 220.263 835.06 220.263C833.386 220.263 832.03 221.62 832.03 223.293C832.03 224.966 833.386 226.323 835.06 226.323Z" fill="#F2F2F2"/>
                <path d="M1091.69 520.822V552.163C1091.69 554.533 1091.22 556.879 1090.31 559.069C1089.41 561.258 1088.08 563.248 1086.4 564.924C1084.73 566.599 1082.74 567.929 1080.55 568.835C1078.36 569.742 1076.01 570.209 1073.64 570.208H596.479C594.109 570.209 591.762 569.742 589.573 568.835C587.383 567.929 585.393 566.599 583.718 564.924C582.042 563.248 580.713 561.258 579.806 559.069C578.899 556.879 578.433 554.533 578.433 552.163V520.822H1091.69Z" fill="#2F2E41"/>
                <path d="M968.978 667.466V673.526H642.968V668.678L643.417 667.466L651.452 645.651H962.312L968.978 667.466Z" fill="#2F2E41"/>
                <path d="M1094.44 661.534C1093.84 664.073 1091.6 666.751 1086.54 669.284C1068.36 678.373 1031.39 666.86 1031.39 666.86C1031.39 666.86 1002.91 662.012 1002.91 649.287C1003.71 648.739 1004.55 648.244 1005.41 647.803C1013.05 643.759 1038.39 633.781 1083.33 648.225C1086.64 649.264 1089.59 651.202 1091.87 653.822C1093.69 655.954 1095.12 658.657 1094.44 661.534Z" fill="#2F2E41"/>
                <path opacity="0.1" d="M1094.44 661.534C1072.19 670.06 1052.35 670.696 1032 656.559C1021.73 649.432 1012.41 647.669 1005.41 647.802C1013.05 643.759 1038.39 633.781 1083.33 648.225C1086.64 649.264 1089.59 651.202 1091.87 653.822C1093.69 655.953 1095.12 658.657 1094.44 661.534Z" fill="black"/>
                <path d="M1066.54 656.559C1070.89 656.559 1074.42 655.473 1074.42 654.135C1074.42 652.796 1070.89 651.711 1066.54 651.711C1062.19 651.711 1058.66 652.796 1058.66 654.135C1058.66 655.473 1062.19 656.559 1066.54 656.559Z" fill="#F2F2F2"/>
                <path d="M835.06 557.18C841.418 557.18 846.573 552.026 846.573 545.667C846.573 539.308 841.418 534.154 835.06 534.154C828.701 534.154 823.546 539.308 823.546 545.667C823.546 552.026 828.701 557.18 835.06 557.18Z" fill="#F2F2F2"/>
                <path opacity="0.1" d="M968.978 667.466V673.526H642.968V668.678L643.417 667.466H968.978Z" fill="black"/>
                <path d="M316.609 159H108.609V401H316.609V159Z" fill="#2F2E41"/>
                <path d="M337.609 135H87.6091V221H337.609V135Z" fill="#3F3D56"/>
                <path d="M337.609 237H87.6091V323H337.609V237Z" fill="#3F3D56"/>
                <path d="M337.609 339H87.6091V425H337.609V339Z" fill="#3F3D56"/>
                <path opacity="0.4" d="M287.609 150H271.609V166H287.609V150Z" fill="#6C63FF"/>
                <path opacity="0.8" d="M310.609 150H294.609V166H310.609V150Z" fill="#6C63FF"/>
                <path d="M333.609 150H317.609V166H333.609V150Z" fill="#6C63FF"/>
                <path opacity="0.4" d="M287.609 251H271.609V267H287.609V251Z" fill="#6C63FF"/>
                <path opacity="0.8" d="M310.609 251H294.609V267H310.609V251Z" fill="#6C63FF"/>
                <path d="M333.609 251H317.609V267H333.609V251Z" fill="#6C63FF"/>
                <path opacity="0.4" d="M287.609 352H271.609V368H287.609V352Z" fill="#6C63FF"/>
                <path opacity="0.8" d="M310.609 352H294.609V368H310.609V352Z" fill="#6C63FF"/>
                <path d="M333.609 352H317.609V368H333.609V352Z" fill="#6C63FF"/>
                </g>
            </svg>
        </div>
        </div>

        <div className="lg:w-[1100px] sm:w-[100vw] flex flex-col items-center justify-center sm:mx-auto mx-4 p-10 mb-8">
            <h1 className="text-gray-800 sm:text-6xl text-4xl text-center mb-10">Tecnologie che conosco</h1>
            <div>
                <div className="bg-gray-100 border-2 border-gray-200 shadow-md rounded-2xl flex sm:flex-row flex-col items-center p-10 w-full mb-8 gap-8">
                    <img src={react} alt="" className="w-[200px]" />
                    <div className="text-center">
                        <h2 className="sm:text-4xl text-2xl mb-6">Livello di conoscenza</h2>
                        <p className="sm:text-6xl text-3xl">8/10</p>
                    </div>
                </div>

                <div className="bg-gray-100 border-2 border-gray-200 shadow-md rounded-2xl flex sm:flex-row flex-col items-center p-10 w-full mb-8 gap-8">
                    <img src={vue} alt="" className="w-[200px]" />
                    <div className="text-center">
                        <h2 className="sm:text-4xl text-2xl mb-6">Livello di conoscenza</h2>
                        <p className="sm:text-6xl text-3xl">6/10</p>
                    </div>
                </div>

                <div className="bg-gray-100 border-2 border-gray-200 shadow-md rounded-2xl flex sm:flex-row flex-col items-center p-10 w-full mb-8 gap-8">
                    <img src={node} alt="" className="w-[200px]" />
                    <div className="text-center">
                        <h2 className="sm:text-4xl text-2xl mb-6">Livello di conoscenza</h2>
                        <p className="sm:text-6xl text-3xl">9/10</p>
                    </div>
                </div>

                <div className="bg-gray-100 border-2 border-gray-200 shadow-md rounded-2xl flex sm:flex-row flex-col items-center p-10 w-full mb-8 gap-8">
                    <img src={mongodb} alt="" className="w-[200px]" />
                    <div className="text-center">
                        <h2 className="sm:text-4xl text-2xl mb-6">Livello di conoscenza</h2>
                        <p className="sm:text-6xl text-3xl">8/10</p>
                    </div>
                </div>

                <div className="bg-gray-100 border-2 border-gray-200 shadow-md rounded-2xl flex sm:flex-row flex-col items-center p-10 w-full mb-8 gap-8">
                    <img src={laravel} alt="" className="w-[200px]" />
                    <div className="text-center">
                        <h2 className="sm:text-4xl text-2xl mb-6">Livello di conoscenza</h2>
                        <p className="sm:text-6xl text-3xl">7/10</p>
                    </div>
                </div>

                <div className="bg-gray-100 border-2 border-gray-200 shadow-md rounded-2xl flex sm:flex-row flex-col items-center p-10 w-full mb-8 gap-8">
                    <img src={mysql} alt="" className="w-[200px]" />
                    <div className="text-center">
                        <h2 className="sm:text-4xl text-2xl mb-6">Livello di conoscenza</h2>
                        <p className="sm:text-6xl text-3xl">7/10</p>
                    </div>
                </div>

                <div className="bg-gray-100 border-2 border-gray-200 shadow-md rounded-2xl flex sm:flex-row flex-col items-center p-10 w-full mb-8 gap-8">
                    <img src={python} alt="" className="w-[200px]" />
                    <div className="text-center">
                        <h2 className="sm:text-4xl text-2xl mb-6">Livello di conoscenza</h2>
                        <p className="sm:text-6xl text-3xl">6/10</p>
                    </div>
                </div>

                <div className="bg-gray-100 border-2 border-gray-200 shadow-md rounded-2xl flex sm:flex-row flex-col items-center p-10 w-full mb-8 gap-8">
                    <img src={django} alt="" className="w-[200px]" />
                    <div className="text-center">
                        <h2 className="sm:text-4xl text-2xl mb-6">Livello di conoscenza</h2>
                        <p className="sm:text-6xl text-3xl">5/10</p>
                    </div>
                </div>
            </div>
        </div>

        <Form />
    </>
  )
}

export default Home