import python from '../../images/python.png'
import MenuPython from '../../components/MenuPython'

const python_sintassi = () => {
  return (
    <div className="lg:w-[1100px] sm:w-[100vw] min-h-[100vh] sm:mx-auto mx-4 flex flex-col items-center my-20 bg-gray-200 p-8 rounded-lg">
        <img src={python} alt="" className='w-[200px]' />
        <div className='grid grid-cols-4 w-full mt-6'>
            <div className='lg:col-span-3 col-span-4 lg:mr-4'>
                <h1 className='text-3xl text-indigo-700 font-semibold'>Sintassi Python</h1>
                <p className='mb-4'>
                    Mentre negli altri linguaggi di programmazione, per separare le istruzioni o i blocchi di istruzioni viene usato il <span className='p-1 bg-gray-300 text-indigo-600'>;</span>, in Python gli spazzi bianchi e l’indentazione costruiscono la struttura del codice.
                </p>

                <p>
                    Di seguito un esempio di codice scritto in Python:
                </p>
                <div className='w-full py-3 px-6 bg-slate-900 text-white mb-4'>
                    <code>
                        <p className='text-green-700'># define main function to print out something</p>
                        def main():
                        <p>&nbsp;&nbsp;&nbsp;&nbsp;i = 1</p>
                        <p>&nbsp;&nbsp;&nbsp;&nbsp;max = 10</p>
                        <p>&nbsp;&nbsp;&nbsp;&nbsp;while (i &lt; max):</p>   
                        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;print(i)</p>
                        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;i = i + 1</p>    
                        <br />
                        <p className='text-green-700'># call function main</p>
                        main()  
                    </code>  
                </div>
                
                <p className="mb-4">
                    Il significato del codice per il momento non è importante, bisogna prestare attenzione alla struttura invece.
                </p>

                <p className="mb-4">
                    Alla fine di ogni linea non è presente il punto e virgola per determinare la fine del blocco. Ma viene usata l’indentazione per formare la struttura del codice.
                </p>

                <p className="mb-4">
                    Grazie all’utilizzo di questo sistema, avremmo un codice molto più leggibili e chiaro. Uno stile di codifica uniforme tra uno sviluppatore e l’altro, rendendo la manutenzione sul codice altrui molto più semplice.
                </p>

                <h2 className='font-bold'>Commenti</h2>
                <p className="mb-4">
                    I commenti servono per descrivere la funzione di un pezzo di codice e vengono ignorati quando Python esegue il codice.
                </p>

                <h2 className='font-bold'>Commentare un blocco</h2>
                <p>
                    In Python, per poter commentare una linea di codice bisogna inserire il simbolo <span className='p-1 bg-gray-300 text-indigo-600'>#</span>, all’inizio della stessa:
                </p>
                <div className='w-full py-3 px-6 bg-slate-900 text-white mb-4'>
                    <p className='text-green-700'># Commento in Python</p>
                </div>

                <h2 className='font-bold'>Commentare in linea</h2>
                <p>
                    Per commento in linea si intende quando si inserisce un commento sulla stesa linea del blocco di codice: 
                </p>
                <div className='w-full py-3 px-6 bg-slate-900 text-white mb-4'>
                    <p>x = y + 5<span className='text-green-700'> # Commento in Python</span></p>
                </div>

                <h2 className='font-bold'>Continuazione di un blocco</h2>
                <p className="mb-4">
                    Per portare a capo un blocco di codice molto lungo, possiamo usare il backslash <span className='p-1 bg-gray-300 text-indigo-600'>\</span>.
                </p>

                <p>Esempio:</p>
                <div className='w-full py-3 px-6 bg-slate-900 text-white mb-4'>
                    if (a == True) and (b == False) and \
                    <p>&nbsp;&nbsp;&nbsp;&nbsp;(c == True):</p>
                    <p>&nbsp;&nbsp;&nbsp;&nbsp;print("Continuazione dello statement")</p>
                </div>

                <h2 className='font-bold'>Identificatori</h2>
                <p className="mb-4">
                    Gli identificatori sono i nomi che identificato le variabili, funzioni, moduli, classi e tutti gli altri oggetti in Python.
                </p>

                <p className="mb-4">
                    Il nome che utilizziamo come identificatore deve iniziare con una lettera oppure il <span className='p-1 bg-gray-300 text-indigo-600'>_</span>. I caratteri successivi possono essere numerici o il <span className='p-1 bg-gray-300 text-indigo-600'>_</span>.
                </p>

                <p className="mb-4">
                    In Python per convenzione si utilizza lo <strong>snake case</strong>, che è la pratica di scrivere gli identificatori separando le parole che li compongono tramite trattino basso: <strong>snake_case</strong>.
                </p>

                <p className="mb-4">
                    Python è case-sensitive, quindi per esempio <span className='p-1 bg-gray-300 text-indigo-600'>counter</span> e <span className='p-1 bg-gray-300 text-indigo-600'>Counter</span> sono differenti.
                </p>

                <p className="mb-4">
                    Non è possibile usare le <strong>keywords</strong> di Python come nome per gli identificatori.
                </p>

                <p>Di seguito una lista di keywords in Python:</p>
                <p className='mb-4 italic'>
                    False      class      finally    is         return
                    <br />
                    None       continue   for        lambda     try
                    <br />
                    True       def        from       nonlocal   while
                    <br />
                    and        del        global     not        with
                    <br />
                    as         elif       if         or         yield
                    <br />
                    assert     else       import     pass
                    <br />
                    break      except     in         raise
                </p>

                <p className="mb-4">
                    Python è un linguaggio in continua crescita ed evoluzione. Quindi le keywords saranno aggiornate e aumentate.
                </p>

                <p className="mb-4">
                    Per questo Python fornisce un modulo speciale per avere sempre una lista aggiornata di tutte le keywords chiamato <strong>keyword</strong>.
                </p>

                <p>
                    Per utilizzarlo, usare il seguente comando:
                </p>
                <div className='w-full py-3 px-6 bg-slate-900 text-white mb-4'>
                    import keyword
                    <br />
                    print(keyword.kwlist)
                </div>

                <h2 className='font-bold'>Stringhe letterali</h2>
                <p className="mb-4">
                    Python utilizza il singolo apice <span className='p-1 bg-gray-300 text-indigo-600'>'</span>, i doppi apici <span className='p-1 bg-gray-300 text-indigo-600'>"</span>, i tripli singoli apici <span className='p-1 bg-gray-300 text-indigo-600'>'''</span> e i tripli doppi apici <span className='p-1 bg-gray-300 text-indigo-600'>"""</span> per denominare una stringa letterale.
                </p>

                <p className="mb-4">
                    La stringa deve essere circondata da apici dello stesso tipo. Se per esempio, si utilizza il singolo apice all’inizio della stringa, essa dovrà finire con un singolo apice.
                </p>

                <p>
                    Di seguito alcuni esempi di stringhe letterali:
                </p>
                <div className='w-full py-3 px-6 bg-slate-900 text-white mb-4'>
                    s = 'This is a string'
                    <br />
                    print(s)
                    <br />
                    <br />
                    s = "Another string using double quotes"
                    <br />
                    print(s)
                    <br />
                    <br />
                    s = ''' string can span
                    <p>&nbsp;&nbsp;&nbsp;&nbsp;multiple line '''</p>
                    print(s)
                </div>
            </div>
            <MenuPython />
        </div>
    </div>
  )
}

export default python_sintassi