import { useState } from 'react'
import {send} from '@emailjs/browser'

const Form = () => {
  const [name, setName] = useState('')
  const [lastname, setLastname] = useState('')
  const [email, setEmail] = useState('')
  const [message, setMessage] = useState('')

  const [show, setShow] = useState(false)

  const onSubmit = (e) => {
    e.preventDefault()
    const template = {
      name,
      lastname,
      email,
      message
    }

    send('service_hq6lqoi', 'template_337uuba', template, 'gTAxB-Yue-ohdktg7')
    .then(() => {
      setShow(true)
      setName('')
      setLastname('')
      setEmail('')
      setMessage('')

      setTimeout(() => {
        setShow(false)
      }, 3000)
    })
    .catch((err) => console.log(err))
  }

  return (
    <div className="w-full bg-neutral-900 flex flex-col items-center justify-center py-10 px-4 relative">
            {show && (
              <div className='absolute top-4 right-4 p-4 rounded-lg bg-white border-4 border-green-600 text-gray-800 text-lg transition-all'>
                <h1>Messaggio inviato con successo!</h1>
              </div>
            )}
            <h1 className="text-gray-200 sm:text-6xl text-4xl mb-10">Contattami</h1>
            <form onSubmit={onSubmit} method="POST" className="sm:w-[1100px] w-full">
              <div className="overflow-hidden shadow sm:rounded-2xl">
                <div className="bg-slate-900 mb-8 rounded-2xl group border-[3px] border-indigo-600 px-4 py-5 sm:p-6">
                  <div className="grid grid-cols-6 gap-6">
                    <div className="col-span-6 sm:col-span-3">
                      <label htmlFor="first-name" className="block text-lg font-medium text-gray-200">
                        Nome
                      </label>
                      <input
                        type="text"
                        name="first-name"
                        id="first-name"
                        autoComplete="given-name"
                        className="mt-1 block w-full rounded-md bg-slate-800 border-indigo-500 shadow-sm focus:ring-indigo-500 sm:text-lg text-gray-200"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                    </div>

                    <div className="col-span-6 sm:col-span-3">
                      <label htmlFor="last-name" className="block text-lg font-medium text-gray-200">
                        Cognome
                      </label>
                      <input
                        type="text"
                        name="last-name"
                        id="last-name"
                        autoComplete="family-name"
                        className="mt-1 block w-full rounded-md bg-slate-800 border-indigo-500 shadow-sm focus:ring-indigo-500 sm:text-lg text-gray-200"
                        value={lastname}
                        onChange={(e) => setLastname(e.target.value)}
                      />
                    </div>

                    <div className="col-span-6">
                      <label htmlFor="email-address" className="block text-lg font-medium text-gray-200">
                        Email
                      </label>
                      <input
                        type="text"
                        name="email-address"
                        id="email-address"
                        autoComplete="email"
                        className="mt-1 block w-full rounded-md bg-slate-800 border-indigo-500 shadow-sm focus:ring-indigo-500 sm:text-lg text-gray-200"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>

                    <div className="col-span-6">
                      <label htmlFor="email-address" className="block text-lg font-medium text-gray-200">
                        Testo della richiesta
                      </label>
                      <textarea 
                        className="mt-1 block w-full rounded-md bg-slate-800 border-indigo-500 shadow-sm focus:ring-indigo-500 sm:text-lg text-gray-200"
                        rows="5"
                        onChange={(e) => setMessage(e.target.value)}
                        value={message}
                    ></textarea>
                    </div>
                  </div>

                  <button
                    type="submit"
                    className="mt-6 rounded bg-indigo-600 py-2 px-4 text-lg font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  >
                    Invia richiesta
                  </button>
                </div>
              </div>
            </form>
        </div>
  )
}

export default Form