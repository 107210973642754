import {useState} from 'react'

const PasswordApp = () => {
    const [length, setLength] = useState(10)
    const [upperHas, setUpperHas] = useState(true)
    const [lowerHas, setLowerHas] = useState(true)
    const [numberHas, setNumberHas] = useState(true)
    const [simbolHas, setSimbolHas] = useState(true)
    const [password, setPassword] = useState('')

    const getRandomLower = () => {
        return String.fromCharCode(Math.floor(Math.random() * 26) + 97)
    }

    const getRandomUpper = () => {
        return String.fromCharCode(Math.floor(Math.random() * 26) + 65)
    }

    const getRandomNumber = () => {
        return String.fromCharCode(Math.floor(Math.random() * 10) + 48)
    }

    const getRandomSymbol = () => {
        const symbols = '!@#$%^&*()[]{}=<>/,.'
        return symbols[Math.floor(Math.random() * symbols.length)]
    }

    const generatePassword = (lower, upper, number, simbol) => {
        let generatedPassword = ''
        const typeCount = lower + upper + number + simbol
        const typesArr = [{lower}, {upper}, {number}, {simbol}].
            filter(item => Object.values(item)[0])

        if (typeCount === 0) {
            return setPassword('')
        }

        for (let i = 0; i < length; i += typeCount) {
            typesArr.forEach(type => {
                const funcName = Object.keys(type)[0]
                if (funcName == 'lower') {
                    generatedPassword += getRandomLower()
                } else if (funcName == 'upper') {
                    generatedPassword += getRandomUpper()
                } else if (funcName == 'number') {
                    generatedPassword += getRandomNumber()
                } else if (funcName == 'simbol') {
                    generatedPassword += getRandomSymbol()
                }
            })
        }

        setPassword(generatedPassword.slice(0, length))
    }

    const copyPassword = () => {
        const textarea = document.createElement('textarea')

        if (!password) {
            return ''
        }

        textarea.value = password
        document.body.appendChild(textarea)
        textarea.select()
        document.execCommand('copy')
        setPassword('')
        textarea.remove()
        alert('Password copiata')
    }

  return (
    <div className='h-[100vh] w-full flex items-center justify-center'>
        <div className='w-[400px] p-4 bg-indigo-800 shadow-inner shadow-indigo-600'>
            <h1 className='text-center text-white text-2xl mb-4'>Generatore di password</h1>
            <div className='flex justify-between items-center w-full bg-indigo-900 text-white p-4 mb-4'>
                <h1 
                    className='text-lg'
                >{password}</h1>
                <button 
                    className='p-2 bg-indigo-600 hover:bg-indigo-700'
                    onClick={copyPassword}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 01-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 011.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 00-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 01-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 00-3.375-3.375h-1.5a1.125 1.125 0 01-1.125-1.125v-1.5a3.375 3.375 0 00-3.375-3.375H9.75" />
                    </svg>
                </button>
            </div>
            <div className='w-full flex justify-between items-center mb-4'>
                <h1 className='text-white text-lg'>Lunghezza password</h1>
                <input 
                    type="number" 
                    min="4" 
                    max="20"
                    value={length}
                    onChange={(e) => setLength(e.target.value)}
                />
            </div>
            <div className='w-full flex justify-between items-center mb-4'>
                <h1 className='text-white text-lg'>Includere caratteri maiuscoli</h1>
                <input 
                    type="checkbox" 
                    name="" 
                    id="" 
                    checked={upperHas}
                    onChange={() => setUpperHas(!upperHas)}
                />
            </div>
            <div className='w-full flex justify-between items-center mb-4'>
                <h1 className='text-white text-lg'>Includere caratteri minuscoli</h1>
                <input 
                    type="checkbox" 
                    name="" 
                    id="" 
                    checked={lowerHas}
                    onChange={() => setLowerHas(!lowerHas)}
                />
            </div>
            <div className='w-full flex justify-between items-center mb-4'>
                <h1 className='text-white text-lg'>Includere numeri</h1>
                <input 
                    type="checkbox" 
                    name="" 
                    id="" 
                    checked={numberHas}
                    onChange={() => setNumberHas(!numberHas)}
                />
            </div>
            <div className='w-full flex justify-between items-center mb-4'>
                <h1 className='text-white text-lg'>Includere simboli</h1>
                <input 
                    type="checkbox" 
                    name="" 
                    id="" 
                    checked={simbolHas}
                    onChange={() => setSimbolHas(!simbolHas)}
                />
            </div>
            <div className='w-full flex justify-between items-center mb-4'>
                <button onClick={() => generatePassword(lowerHas, upperHas, numberHas, simbolHas)} className='w-full p-4 text-center bg-indigo-600 text-white text-lg hover:bg-indigo-700'>Crea password</button>
            </div>
        </div>
    </div>
  )
}

export default PasswordApp