import {useState} from 'react'

const QuizApp = () => {
    const body = {
        backgroundColor: "#b8c6db",
        backgroundImage: "linear-gradient(315deg, #b8c6db 0%, #f5f7fa 100%)",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        overflow: "hidden"
    }

    const quizContainer = {
        backgroundColor: "#fff",
        borderRadius: "10px",
        boxShadow: "0 0 10px 2px rgba(100, 100, 100, 0.1)",
        width: "350px",
        overflow: "hidden"
    }

    const button = {
        backgroundColor: "#8e44ad",
        border: "none",
        color: "#fff",
        display: "block",
        width: "100%",
        cursor: "pointer",
        fontSize: "1.1rem",
        fontFamily: "inherit",
        padding: "1.3rem"
    }

    const quizData = {
        totalQuestions: 4,
        perQuestionScore: 5,
        questions: [
            {
                question: "Quale linguaggio viene usato all'interno di un web browser?",
                choices: ["Java", "C", "Python", "JavaScript"],
                correctAnswer: "JavaScript",
            },
            {
                question: "Qual'è il significato dell'acronimo CSS?",
                choices: ["Central Style Sheets", "Cascading Style Sheets", "Cascading Simple Sheets", "Cars SUVs Sailboats"],
                correctAnswer: "Cascading Style Sheets",
            },
            {
                question: "Qual'è il significato dell'acronimo HTML?",
                choices: ["Hypertext Markup Language", "Hypertext Markdown Language", "Hyperloop Machine Language", "Helicopters Terminals Motorboats Lamborginis"],
                correctAnswer: "Hypertext Markup Language",
            },
            {
                question: "In che anno viene rilasciato Javascript?",
                choices: ["1996", "1995", "1994", "nessuna delle precedenti risposte"],
                correctAnswer: "1995",
            },
        ]     
    };

    const [activeQuestion, setActiveQuestion] = useState(0)
    const [selectedAnswer, setSelectedAnswer] = useState('')
    const [showResult, setShowResult] = useState(false)
    const [selectedAnswerIndex, setSelectedAnswerIndex] = useState(null)
    const [result, setResult] = useState({
        score: 0,
        correctAnswers: 0,
        wrongAnswers: 0,
    })

    const {questions} = quizData
    const {question, choices, correctAnswer} = questions[activeQuestion]

    const onClickNext = () => {
        setSelectedAnswerIndex(null)
        setResult((prev) =>
          selectedAnswer
            ? {
                ...prev,
                score: prev.score + 5,
                correctAnswers: prev.correctAnswers + 1,
              }
            : { ...prev, wrongAnswers: prev.wrongAnswers + 1 }
        )
        if (activeQuestion !== questions.length - 1) {
          setActiveQuestion((prev) => prev + 1)
        } else {
          setActiveQuestion(0)
          setShowResult(true)
        }
    }

    const onAnswerSelected = (answer, index) => {
        setSelectedAnswerIndex(index)
        if (answer === correctAnswer) {
          setSelectedAnswer(true)
        } else {
          setSelectedAnswer(false)
        }
      }

    const addLeadingZero = (number) => (number > 9 ? number : `0${number}`)

  return (
    <>
        <div style={body} id='body'>
            <div style={quizContainer} id="quiz" className='p-6'>
                {showResult ? (
                    <div>
                        <h1 className='text-3xl mb-4 font-semibold'>Risultato: {result.score >= 15 ? 'Test superato' : 'Test fallito'}</h1>
                        <p className='text-lg'>
                            Domande totali: <span>{questions.length}</span>
                        </p>
                        <p className='text-lg'>
                            Punteggio totale: <span className={result.score >= 15 ? 'text-green-600' : 'text-red-600'}>{result.score}</span>
                        </p>
                        <p className='text-lg text-green-600'>
                            Risposte corrette: <span>{result.correctAnswers}</span>
                        </p>
                        <p className='text-lg text-red-600'>
                            Risposte sbagliate: <span>{result.wrongAnswers}</span>
                        </p>
                    </div>
                ) : (
                    <>
                    <div>
                        <div className='text-lg mb-4'>
                            <span>
                                {addLeadingZero(activeQuestion + 1)}
                            </span>
                            <span>
                                /{addLeadingZero(questions.length)}
                            </span>
                        </div>

                        <h2 id="question" className='mb-2 text-2xl font-semibold'>{question}</h2>
                        <ul>
                            {choices.map((answer, index) => (
                                <li 
                                    className={`mb-4 hover:bg-black/25 rounded transition p-2 cursor-pointer ${selectedAnswerIndex === index ? 'bg-indigo-600 text-white hover:!bg-indigo-700' : null}`}
                                    onClick={() => onAnswerSelected(answer, index)}
                                    key={answer}
                                >{answer}</li>
                            ))}
                        </ul>
                    </div>
                    <button 
                        onClick={onClickNext} 
                        style={button} 
                        id="submit" 
                        className='mt-4'
                        disabled={selectedAnswerIndex === null}
                    >
                        {activeQuestion === questions.length - 1 ? 'Test finito' : 'Prossima domanda'}
                    </button>
                </>
                )}  
            </div>
        </div>
    </>
  )
}

export default QuizApp