import {useEffect, useState} from 'react'

const MovieApp = () => {
    const API_URL = 'https://api.themoviedb.org/3/discover/movie?sort_by=popularity.desc&api_key=36f747ce08e31be59559271857207b9a&page=1'
    const IMG_PATH = 'https://image.tmdb.org/t/p/w1280'
    const SEARCH_API = 'https://api.themoviedb.org/3/search/movie?api_key=36f747ce08e31be59559271857207b9a&query="'

    const [movies, setMovies] = useState(null)

    useEffect(() => {
        getMovies(API_URL)
    }, [])

    const getMovies = async (url) => {
        const res = await fetch(url)
        const data = await res.json()

        setMovies(data.results)
        console.log(data.results)
    }

    const searchFilm = async (filmName) => {
        if (filmName && !filmName !== '') {
            getMovies(SEARCH_API + filmName)
        } else {
            getMovies(API_URL)
        }
    }

  return (
    <div>
        {!movies ? (
            <h1 className='text-3xl text-white mt-50'>Nessun film trovato</h1>
        ) : (<div className='mt-20 flex flex-wrap justify-center w-full'>
                <div className='w-full p-6'>
                    <input 
                        type="text" 
                        placeholder='Cerca un film'
                        onChange={(e) => {searchFilm(e.target.value)}}
                        className='bg-indigo-800 rounded-full text-white placeholder:text-gray-300 placeholder:italic border-indigo-600 px-4'
                    />
                </div>
                {movies.map((movie, index) => (
                    <div key={index} className="overflow-hidden w-[400px] bg-gray-200 relative rounded m-6">
                        <div>
                            <div className='w-full p-4 flex justify-between items-center'>
                                <h1 className='text-xl font-semibold'>{movie.title}</h1>
                                <div className='p-2 bg-gray-300'>
                                    <h1 className={`text-xl ${movie.vote_average >= 8 ? 'text-green-600' : movie.vote_average >= 5 ? 'text-yellow-600' : 'text-red-600'}`}>{movie.vote_average}</h1>
                                </div>
                            </div>
                            <img className='w-full' src={`${IMG_PATH}${movie.poster_path}`} alt="" />
                        </div>
                        <div className='p-4'>
                            <h3 className='text-lg font-semibold mb-4'>Trama film</h3>
                            <p className='italic'>{movie.overview}</p>
                        </div>
                    </div>
                ))}
        </div>)}
    </div>
  )
}

export default MovieApp