import { Link } from 'react-router-dom'

const MenuPython = () => {
  return (
    <div className='lg:col-span-1 col-span-4'>
                <div className='mb-4'>
                    <h1 className='font-semibold text-lg'>Per iniziare</h1>
                    <Link to="/article/python/1" className='text-indigo-600 block my-1'>Che cos'è Python</Link>
                    <Link to="/article/python/2" className='text-indigo-600 block my-1'>Installazione di Python</Link>
                    <Link to="/article/python/3" className='text-indigo-600 block my-1'>Visual Studio Code</Link>
                    <Link to="/article/python/4" className='text-indigo-600 block my-1'>Introduzione a Python</Link>
                </div>
                <div className='mb-4'>
                    <h1 className='font-semibold text-lg'>Le basi di Python</h1>
                    <Link to="/article/python/5" className='text-indigo-600 block my-1'>Sintassi Python</Link>
                    <Link to="/article/python/6" className='text-indigo-600 block my-1'>Variabili</Link>
                    <Link to="/article/python/7" className='text-indigo-600 block my-1'>Stringhe</Link>
                    <Link to="/article/python/8" className='text-indigo-600 block my-1'>Numeri</Link>
                    <Link to="/article/python/9" className='text-indigo-600 block my-1'>Operatori</Link>
                    <Link to="/article/python/10" className='text-indigo-600 block my-1'>Constanti</Link>
                    <Link to="/article/python/11" className='text-indigo-600 block my-1'>Conversioni</Link>
                    <Link to="/article/python/12" className='text-indigo-600 block my-1'>Operatori di comparazione</Link>
                    <Link to="/article/python/13" className='text-indigo-600 block my-1'>Operatori logici</Link>
                </div>
                <div className='mb-4'>
                    <h1 className='font-semibold text-lg'>Controllo del flusso dati</h1>
                    <Link to="/article/python/14" className='text-indigo-600 block my-1'>if...else</Link>
                    <Link to="/article/python/15" className='text-indigo-600 block my-1'>Operatore ternario</Link>
                    <Link to="/article/python/16" className='text-indigo-600 block my-1'>Loop con range</Link>
                    <Link to="/article/python/17" className='text-indigo-600 block my-1'>While</Link>
                    <Link to="/article/python/18" className='text-indigo-600 block my-1'>Break</Link>
                    <Link to="/article/python/19" className='text-indigo-600 block my-1'>Continue</Link>
                    <Link to="/article/python/20" className='text-indigo-600 block my-1'>Pass</Link>
                </div>
                <div className='mb-4'>
                    <h1 className='font-semibold text-lg'>Funzioni</h1>
                    <Link to="/article/python/21" className='text-indigo-600 block my-1'>Funzioni</Link>
                    <Link to="/article/python/22" className='text-indigo-600 block my-1'>Parametro di default</Link> 
                    <Link to="/article/python/23" className='text-indigo-600 block my-1'>Keyword argument</Link>
                    <Link to="/article/python/24" className='text-indigo-600 block my-1'>Lambda expression</Link>
                    <Link to="/article/python/25" className='text-indigo-600 block my-1'>Docstrings</Link>
                </div>
                <div className='mb-4'>
                    <h1 className='font-semibold text-lg'>List e Tuple</h1>
                    <Link to="/article/python/26" className='text-indigo-600 block my-1'>List</Link>
                    <Link to="/article/python/27" className='text-indigo-600 block my-1'>Tuple</Link>
                    <Link to="/article/python/28" className='text-indigo-600 block my-1'>Sort list</Link>
                    <Link to="/article/python/29" className='text-indigo-600 block my-1'>List slice</Link>
                    <Link to="/article/python/30" className='text-indigo-600 block my-1'>Unpacking di una lista o tuple</Link>
                    <Link to="/article/python/31" className='text-indigo-600 block my-1'>Usare loop per iterare una list</Link>
                    <Link to="/article/python/32" className='text-indigo-600 block my-1'>Index of</Link>
                    <Link to="/article/python/33" className='text-indigo-600 block my-1'>Map function</Link>
                    <Link to="/article/python/34" className='text-indigo-600 block my-1'>Filter function</Link>
                    <Link to="/article/python/35" className='text-indigo-600 block my-1'>Reduce function</Link>
                </div>
                <div className='mb-4'>
                    <h1 className='font-semibold text-lg'>Dictionary</h1>
                    <Link to="/article/python/36" className='text-indigo-600 block my-1'>Dictionary</Link>      
                </div>
                <div className='mb-4'>
                    <h1 className='font-semibold text-lg'>Set</h1>
                    <Link to="/article/python/37" className='text-indigo-600 block my-1'>Set</Link>
                    <Link to="/article/python/38" className='text-indigo-600 block my-1'>Set union</Link> 
                    <Link to="/article/python/39" className='text-indigo-600 block my-1'>Set intersection</Link> 
                    <Link to="/article/python/40" className='text-indigo-600 block my-1'>Set difference</Link> 
                    <Link to="/article/python/41" className='text-indigo-600 block my-1'>Symmetric difference</Link> 
                    <Link to="/article/python/42" className='text-indigo-600 block my-1'>Issubset</Link> 
                    <Link to="/article/python/43" className='text-indigo-600 block my-1'>Issuperset</Link> 
                    <Link to="/article/python/44" className='text-indigo-600 block my-1'>Disjoint sets</Link> 
                </div>
                <div className='mb-4'>
                    <h1 className='font-semibold text-lg'>Gestire le eccezioni</h1>
                    <Link to="/article/python/45" className='text-indigo-600 block my-1'>try…except</Link>
                    <Link to="/article/python/46" className='text-indigo-600 block my-1'>try…except…finally</Link> 
                    <Link to="/article/python/47" className='text-indigo-600 block my-1'>try…except…else</Link> 
                </div>
                <div className='mb-4'>
                    <h1 className='font-semibold text-lg'>Modules</h1>
                    <Link to="/article/python/48" className='text-indigo-600 block my-1'>Modules</Link>
                </div>
            </div>
  )
}

export default MenuPython