import python from '../../images/python.png'
import MenuPython from '../../components/MenuPython'
import installazione01 from '../python_guide/img/installazione_01.jpg'
import installazione02 from '../python_guide/img/installazione_02.jpg'
import installazione03 from '../python_guide/img/installazione_03.jpg'

const python_installazione = () => {
  return (
    <div className="lg:w-[1100px] sm:w-[100vw] min-h-[100vh] sm:mx-auto mx-4 flex flex-col items-center my-20 bg-gray-200 p-8 rounded-lg">
        <img src={python} alt="" className='w-[200px]' />
        <div className='grid grid-cols-4 w-full mt-6'>
            <div className='lg:col-span-3 col-span-4 lg:mr-4'>
                <h1 className='text-3xl text-indigo-700 font-semibold mb-4'>Installazione python</h1>

                <h2 className='font-bold'>Installazione su Windows</h2>
                <p className='mb-4'>
                    Scaricare l’ultima versione di Python dalla pagina del download.
                    <br />
                    Fare doppio click sul file d’installazione.
                    <br />
                    Attivare la spunta su <strong>Add Python 3.8 to PATH </strong> e poi procedere con l’installazione cliccando su <strong>Installa Now:</strong>
                </p>

                <img src={installazione01} className="w-full mb-4" alt="" />

                <p className='mb-4'>Una volta completata l’installazione apparirà questa finestra:</p>
                
                <img src={installazione02} className="w-full mb-4" alt="" />

                <h2 className='font-bold'>Verifica l’installazione</h2>
                <p className='mb-4'>Per verificare l’installazione, apriamo il Command Prompt ed eseguiamo il comando <span className='p-1 bg-gray-300 text-indigo-600'>python</span>:</p>

                <img src={installazione03} className="w-full mb-4" alt="" />

                <p className='mb-4'>Se l’output che compare è come quello dell’immagine sopra riportata, Python è stato installato correttamente.</p>

                <p className='mb-4'>Per uscire dal programma premere Crtl-z o premere Invio.</p>

                <h2 className='font-bold'>Installazione su macOS</h2>
                <p className='mb-4'>
                    Scaricare l’ultima versione di Python dalla pagina del download.
                    <br />
                    Fare doppio click sul file d’installazione.
                    <br />
                    Seguire le istruzioni e cliccare sul bottone Next fino a quando l’installazione non viene completata.
                </p>
            </div>
            <MenuPython />
        </div>
    </div>
  )
}

export default python_installazione