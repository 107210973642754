import python from '../../images/python.png'
import MenuPython from '../../components/MenuPython'
import visual_studio_code from '../python_guide/img/visual_studio_code.jpg'

const python_visualstudiocode = () => {
  return (
    <div className="lg:w-[1100px] sm:w-[100vw] min-h-[100vh] sm:mx-auto mx-4 flex flex-col items-center my-20 bg-gray-200 p-8 rounded-lg">
        <img src={python} alt="" className='w-[200px]' />
        <div className='grid grid-cols-4 w-full mt-6'>
            <div className='lg:col-span-3 col-span-4 lg:mr-4'>
                <h1 className='text-3xl text-indigo-700 font-semibold'>Visual Studio Code</h1>
                <p className='mb-4'>Visual Studio Code è un editor di codice sorgente sviluppato da Microsoft per Windows, Linux e macOS. Permette di evidenziare la sintassi di ciascun linguaggio di programmazione, integra il supporto per il debugging, controllo Git, IntelliSense (il completamento automatico delle istruzioni).</p>

                <h2 className='font-bold'>Installazione e setting up Visual Studio Code</h2>
                <p className='mb-4'>Scaricare il file d’installazione dal sito web ufficiale di VS Code.</p>

                <p className="mb-4">Lanciare il file e seguire l’installazione guidata.</p>

                <p className="mb-4">Una volta installato, aprire il programma ed andare sul marketplace <span className='p-1 bg-gray-300 text-indigo-600'>1</span>, inserire nella barra di ricerca la parola chiave “python” <span className='p-1 bg-gray-300 text-indigo-600'>2</span> e cliccare sul primo link che appare <span className='p-1 bg-gray-300 text-indigo-600'>3</span>. Infine installare l’estensione <span className='p-1 bg-gray-300 text-indigo-600'>4</span>.</p>

                <img src={visual_studio_code} alt="" className='w-full' />
            </div>
            <MenuPython />
        </div>
    </div>
  )
}

export default python_visualstudiocode