import python from '../../images/python.png'
import MenuPython from '../../components/MenuPython'

const python_stringhe = () => {
  return (
    <div className="lg:w-[1100px] sm:w-[100vw] min-h-[100vh] sm:mx-auto mx-4 flex flex-col items-center my-20 bg-gray-200 p-8 rounded-lg">
        <img src={python} alt="" className='w-[200px]' />
        <div className='grid grid-cols-4 w-full mt-6'>
            <div className='lg:col-span-3 col-span-4 lg:mr-4'>
                <h1 className='text-3xl text-indigo-700 font-semibold'>Stringhe Python</h1>
                <p className='mb-1'>
                    Una stringa è un insieme di caratteri compreso tra due singoli o doppi.
                    <br />
                    Per esempio:
                </p>
                <div className='w-full py-3 px-6 bg-slate-900 text-white mb-4'>
                    <code>
                        message = 'This is a string in Python'
                        <br />
                        message = "This is also a string"
                    </code>  
                </div>      
                
                <p className='mb-1'>
                    Se una stringa contiene un singolo apice, possiamo utilizzare i doppi apici: 
                </p>
                <div className='w-full py-3 px-6 bg-slate-900 text-white mb-4'>
                    <code>
                        message = "It's a string"
                    </code>  
                </div> 

                <p className='mb-1'>
                    E quando una stringa contiene i doppi apici, possiamo utilizzare i singoli apici:
                </p>
                <div className='w-full py-3 px-6 bg-slate-900 text-white mb-4'>
                    <code>
                        message = '"Beautiful is better than ugly.". Said Tim Peters'
                    </code>  
                </div> 

                <p className='mb-1'>
                    Per poter utilizzare un singolo apice, all’interno di una stringa compresa anch’essa da singoli apici, possiamo utilizzare il backslach <span className='p-1 bg-gray-300 text-indigo-600'>\</span> prima dell’apice contenuto nella stringa:
                </p>
                <div className='w-full py-3 px-6 bg-slate-900 text-white mb-4'>
                    <code>
                        message = 'It\'s also a valid string'
                    </code>  
                </div> 

                <p className='mb-1'>
                    Python considera il backslash <span className='p-1 bg-gray-300 text-indigo-600'>\</span> come un carattere speciale. Ma se non vogliamo che ciò accada, possiamo usare il raw strings aggiungendo la lettera <span className='p-1 bg-gray-300 text-indigo-600'>r</span> prima della stringa: 
                </p>
                <div className='w-full py-3 px-6 bg-slate-900 text-white mb-4'>
                    <code>
                        message = r'C:\python\bin'
                    </code>  
                </div> 

                <h2 className='font-bold'>Stringhe multi linea</h2>
                <p className='mb-1'>
                    Per andare a capo in usa stringa, bisogna una i tripli apici <span className='p-1 bg-gray-300 text-indigo-600'>"""..."""</span> o <span className='p-1 bg-gray-300 text-indigo-600'>'''...'''</span>.
                    <br />
                    Per esempio:
                </p>
                <div className='w-full py-3 px-6 bg-slate-900 text-white mb-4'>
                    <code>
                    help_message = '''
                    <br />
                    Usage: mysql command
                    <p>&nbsp;&nbsp;&nbsp;&nbsp;-h hostname</p>                       
                    <p>&nbsp;&nbsp;&nbsp;&nbsp;-d database name</p>                       
                    <p>&nbsp;&nbsp;&nbsp;&nbsp;-u username</p>              
                    <p>&nbsp;&nbsp;&nbsp;&nbsp;-p password</p>
                    '''
                    <br />
                    print(help_message)
                    </code>  
                </div> 

                <p className='mb-1'>
                    Output:
                </p>
                <div className='w-full py-3 px-6 bg-slate-900 text-white mb-4'>
                    <code>
                    Usage: mysql command
                    <p>&nbsp;&nbsp;&nbsp;&nbsp;-h hostname</p>                       
                    <p>&nbsp;&nbsp;&nbsp;&nbsp;-d database name</p>                       
                    <p>&nbsp;&nbsp;&nbsp;&nbsp;-u username</p>              
                    <p>&nbsp;&nbsp;&nbsp;&nbsp;-p password</p>
                    </code>  
                </div> 
            </div>
            <MenuPython />
        </div>
    </div>
  )
}

export default python_stringhe