import React from 'react'

const NotFound = () => {
  return (
    <div className='min-h-[90vh] w-full flex justify-center items-center'>
        <h1 className='sm:text-6xl text-3xl text-gray-800 font-semibold'><span className='text-indigo-600'>404 - </span>Pagina non trovata</h1>
    </div>
  )
}

export default NotFound