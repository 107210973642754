import python from '../../images/python.png'
import MenuPython from '../../components/MenuPython'

const python_variabili = () => {
  return (
    <div className="lg:w-[1100px] sm:w-[100vw] min-h-[100vh] sm:mx-auto mx-4 flex flex-col items-center my-20 bg-gray-200 p-8 rounded-lg">
        <img src={python} alt="" className='w-[200px]' />
        <div className='grid grid-cols-4 w-full mt-6'>
            <div className='lg:col-span-3 col-span-4 lg:mr-4'>
                <h1 className='text-3xl text-indigo-700 font-semibold'>Variabili Python</h1>
                <p className='mb-4'>
                    Quando si sviluppa un programma, si ha la necessità di gestire molti valori. A questo scopo vengono usate le variabili.
                </p>

                <p>
                    In Python, una variabile è un’etichetta che viene assegnata ad un valore. Per esempio: 
                </p>
                <div className='w-full py-3 px-6 bg-slate-900 text-white mb-4'>
                    <code>
                        message = 'Hello, World!' 
                        <br />
                        print(message)
                    </code>  
                </div>

                <p>Output:</p>
                <div className='w-full py-3 px-6 bg-slate-900 text-white mb-4'>
                    <code>
                        Hello, World!
                    </code>  
                </div>

                <p className='mb-4'>
                    In questo esempio, <span className='p-1 bg-gray-300 text-indigo-600'>message</span> è una variabile a cui è stato assegnato il valore stringa <span className='p-1 bg-gray-300 text-indigo-600'>Hello, World!</span>. La funzione <span className='p-1 bg-gray-300 text-indigo-600'>print()</span> mostra a scremo il valore contenuto nella variabile <span className='p-1 bg-gray-300 text-indigo-600'>message</span>.
                </p>

                <p>Durante lo sviluppo del programma, ad una variabile può essere modificato il valore assegnato. </p>
                <p>Esempio: </p>
                <div className='w-full py-3 px-6 bg-slate-900 text-white mb-4'>
                    <code>
                        message = 'Hello, World!' 
                        <br />
                        print(message)
                        <br />
                        <br />
                        message = 'Good Bye!'
                        <br />
                        print(message)
                    </code>  
                </div>

                <p>Output:</p>
                <div className='w-full py-3 px-6 bg-slate-900 text-white mb-4'>
                    <code>
                        Hello, World!
                        <br />
                        Good Bye!
                    </code>  
                </div>

                <p className="mb-4">
                    Nella linea successive alla variabile <span className='p-1 bg-gray-300 text-indigo-600'>message</span> è stata assegnata la stringa <span className='p-1 bg-gray-300 text-indigo-600'>Good Bye!</span> e stampata a schermo.
                </p>

                <p className="mb-4">
                    Una variabile può avere vari valori allo stato tempo. E questi valori possono essere modificati attraverso il programma.
                </p>
                
                <h2 className='font-bold'>Commenti</h2>
                <p className='mb-4'>
                    Come detto in precedenza, per convenzione in Python si utilizza lo <strong>snake_case</strong> per definire una variabile. Quindi la sintassi sarà la seguente:
                    <br />
                    variable_name = value
                </p>

                <p className="mb-4">
                    Il simbolo <span className='p-1 bg-gray-300 text-indigo-600'>=</span> serve assegnare il valore alla variabile.
                </p>

                <p className="mb-4">
                    Nel seguente esempio viene assegnato il valore di <span className='p-1 bg-gray-300 text-indigo-600'>1</span> alla variabile <span className='p-1 bg-gray-300 text-indigo-600'>counter</span>:
                </p>
                <div className='w-full py-3 px-6 bg-slate-900 text-white mb-4'>
                    <code>
                        counter = 1
                    </code>  
                </div>

                <h2 className='font-bold'>Regole per nominare le variabili</h2>
                <p className='mb-4'>
                    Quando si nomina una variabile, bisogna seguire determinate regole per non avere degli errori.
                </p>

                <p className="mb-4">
                    Di seguito le regole da tenere a mente:
                </p>

                <ul className='list-disc mb-4'>
                    <li className='ml-4'>
                        Il nome di una variabile può contenere solo lettere, numeri e <span className='p-1 bg-gray-300 text-indigo-600'>_</span>. Possono iniziare lettere o <span className='p-1 bg-gray-300 text-indigo-600'>_</span>, ma non con numeri.
                    </li>
                    <li className='ml-4'>
                        Il nome di una variabile non può avere spazi al suo interno. Per dividere le parole si utilizza il <span className='p-1 bg-gray-300 text-indigo-600'>_</span>, per esempio <span className='p-1 bg-gray-300 text-indigo-600'>variable_name</span>.
                    </li>
                    <li className='ml-4'>
                        Come detto in precedenza, gli identificatori (tra cui le variabili) non possono utilizzare le keywords di Python.
                    </li>
                </ul>

                <p className="mb-4">
                    Di seguito alcune best practice per definire il nome di una variabile:
                </p>

                <ul className='list-disc mb-4'>
                    <li className='ml-4'>
                        Il nome della variabile deve essere coinciso e descrittivo.
                    </li>
                    <li className='ml-4'>
                        Usare <span className='p-1 bg-gray-300 text-indigo-600'>_</span> per dividere le parole.
                    </li>
                    <li className='ml-4'>
                        Evitare l’utilizzo della lettera <span className='p-1 bg-gray-300 text-indigo-600'>l</span> e della <span className='p-1 bg-gray-300 text-indigo-600'>O</span> maiuscola, perché potrebbero essere confuse con i numeri <span className='p-1 bg-gray-300 text-indigo-600'>1</span> e <span className='p-1 bg-gray-300 text-indigo-600'>0</span>.
                    </li>
                </ul>
            </div>
            <MenuPython />
        </div>
    </div>
  )
}

export default python_variabili