import {Routes, Route} from 'react-router-dom'
import Footer from './components/Footer';
import Navbar from './components/Navbar';

import {About, Articles, Calculator, Home, MovieApp, NotFound, PasswordApp, Projects, QuizApp} from '../src/views/00_index'

// Python guida
import {Python01, Python02, Python03, Python04, Python05, Python06, Python07} from '../src/views/python_guide/00_index'
 
function App() {
  return (
    <div>
      <Navbar />
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/about' element={<About />} />
        <Route path='/articles' element={<Articles />} />
        <Route path='/projects' element={<Projects />} />
        <Route path='/calculator' element={<Calculator />} />
        <Route path='/quiz-app' element={<QuizApp />} />
        <Route path='/movie-app' element={<MovieApp />} />
        <Route path='/password-app' element={<PasswordApp />} />
        {/* Guida python */}
        <Route path='/article/python/1' element={<Python01 />}/>
        <Route path='/article/python/2' element={<Python02 />}/>
        <Route path='/article/python/3' element={<Python03 />}/>
        <Route path='/article/python/4' element={<Python04 />}/>
        <Route path='/article/python/5' element={<Python05 />}/>
        <Route path='/article/python/6' element={<Python06 />}/>
        <Route path='/article/python/7' element={<Python07 />}/>
        <Route path='*' element={<NotFound />}/>
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
