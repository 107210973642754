import { Link } from "react-router-dom"

const CourseCard = ({title, img, url}) => {
  return (
    <Link 
        to={url} 
        className="w-[250px] bg-gray-200 hover:bg-gray-100 p-8 m-8 rounded-lg flex flex-col items-center justify-between hover:scale-110 hover:rotate-1 transition-all animate-[insert_0.5s_ease-in-out] relative"
    >
        <img 
            src={img}
            alt={title}
            className="w-full"
        />
        <h1 className="text-2xl text-gray-700 font-semibold">{title}</h1>
        
    </Link>
  )
}

export default CourseCard