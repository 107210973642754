import linkedin from '../images/linkedin.png'
import profile from '../images/profilo.png'
import email from '../images/email.png'
import phone from '../images/phone.png'
import github from '../images/github.png'

const About = () => {
  return (
    <div className='bg-gray-300 w-full min-h-[90vh] flex-col lg:flex-row flex items-start justify-center mt-20'>
        <div className='my-4 lg:w-1/2 w-full h-full lg:min-h-[80vh] flex items-center justify-center animate-[insert_0.5s_ease-in-out]'>
            <div className='flex flex-col items-center w-full'>
              <img 
                src={profile} 
                alt="" 
                className='w-[350px] h-[350px] object-cover rounded-full border-[3px] shadow-lg border-indigo-600 bg-gray-200'
              />
              <div className='mt-8 flex flex-col items-start gap-4'>
                <a href="https://www.linkedin.com/in/riccardo-vercellesi-b19a16176/" target="_blank" rel="noreferrer" className='hover:scale-[1.05] transition-all flex items-end'>
                  <img src={linkedin} alt="" className='w-[30px] shadow-lg mr-2' /> <h2 className='text-lg'>LinkedIn</h2>
                </a>

                <a href="https://github.com/RiccardoV1995?tab=repositories" target="_blank" rel="noreferrer" className='hover:scale-[1.05] transition-all flex items-end'>
                  <img src={github} alt="" className='w-[30px] mr-2' /> <h2 className='text-lg'>GitHub</h2>
                </a>

                <div className='hover:scale-[1.05] transition-all flex items-end'>
                  <img src={email} alt="" className='w-[30px] shadow-lg mr-2' /> <h2 className='text-lg'>riccardo.vercellesi@gmail.com</h2>
                </div>

                <div className='hover:scale-[1.05] transition-all flex items-end'>
                  <img src={phone} alt="" className='w-[30px] mr-2' /> <h2 className='text-lg'>+39 348 5542839</h2>
                </div>
              </div>
            </div>
        </div>
        <p className='lg:w-1/2 w-full text-gray-800 px-8 text-lg py-4 text-justify animate-[insert_0.5s_ease-in-out]'>
          <p className='mb-2'>Ciao mi chiamo Riccardo ho 28 e sono appassionato di programmazione e sviluppo web. Questa mia passione è nata circa 3 anni fa, con un progetto tra amici e da quel momento non ho più smesso!</p>

          <p className='mb-2'>Ho iniziato a studiare da autodidatta i linguaggi di formattazione HTML e CSS per poi passare a Javascript, il framework Reac.JS, Node.Js e MongoDB, tramite tutte le risorse gratuite che ho trovato su internet e corsi su Udemy.</p>

          <p className='mb-2'>Dal 2022 ho deciso di affidarmi ad una scuola di programmazione Laramind, con cui ho approfondito React.JS e Node.JS, consolidando le mie conoscenze. Ho anche svolto un corso su PHP, Laravel e mySQL.</p>

          <p className='mb-2'>Recentemente ho deciso di iniziare a studiare il linguaggio Python ed il relativo framework Django, per ampliare le mie conoscenze in vista di un futuro avvicinamento al mondo blockchain...</p>

          <p className="mb-2">Sono alla ricerca di una realtà che possa aiutarmi a crescere ed ampliare le mie conoscenze in questo settore. Al momento sto lavorando come progettista meccanico per un’azienda, ma resto a disposizione per proposte di lavoro, collaborazioni e lavori a progetto anche gratis, per poter iniziare a fare esperienze lavorative in questo mondo.</p>

          <p className="mb-2">Ti ringrazio per aver letto la mia presentazione. Per qualsiasi informazione non esitare a contattarmi!</p>
        </p>
    </div>
  )
}

export default About