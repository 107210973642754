import python from '../../images/python.png'
import MenuPython from '../../components/MenuPython'

const python_cosepython = () => {
  return (
    <div className="lg:w-[1100px] sm:w-[100vw] min-h-[100vh] sm:mx-auto mx-4 flex flex-col items-center my-20 bg-gray-200 p-8 rounded-lg">
        <img src={python} alt="" className='w-[200px]' />
        <div className='grid grid-cols-4 w-full mt-6'>
            <div className='lg:col-span-3 col-span-4 lg:mr-4'>
                <h1 className='text-3xl text-indigo-700 font-semibold'>Che cos'è python</h1>
                <p className='mb-4'>Python è un linguaggio di programmazione di <strong>alto livello</strong>, <strong>interpretativo</strong> e <strong>per scopi generali</strong>.</p>

                <h2 className='font-bold'>Alto livello</h2>
                <p className='mb-4'>Python è un linguaggio di programmazione di alto livello in quanto è molto semplice da imparare. Python non richiede di conoscere i dettagli della programmazione e dell’informatica, ma ci permette di sviluppare programmi efficienti con semplici comandi.</p>

                <h2 className='font-bold'>Interpretativo</h2>
                <p className='mb-4'>
                    Per sviluppare un programma in Python, il codice Python viene scritto all’interno di un file chiamato source code.
                    <br />
                    Per eseguire il file source code, bisogna convertire il codice scritto in un linguaggio che la macchina possa capire. Python <strong>interpreta</strong> il codice scritto all’interno del file source code e restituisce il codice adatto per la macchina.
                </p>

                <h2 className='font-bold'>Per scopi generali</h2>
                <p className='mb-4'>Python è un linguaggio per scopi generali e ciò significa che può essere usato in vari ambiti tra cui:</p>

                <ul className='list-disc mb-4'>
                    <li className='ml-4'>Applicazioni web</li>
                    <li className='ml-4'>Applicazioni big data</li>
                    <li className='ml-4'>Test</li>
                    <li className='ml-4'>Automatizzazione</li>
                    <li className='ml-4'>Data science</li>
                    <li className='ml-4'>Machine learning</li>
                    <li className='ml-4'>AI</li>
                    <li className='ml-4'>Desktop software</li>
                    <li className='ml-4'>Mobile apps</li>
                </ul>

                <h2 className='font-bold'>Vantaggi di Python</h2>
                <p className='mb-4'>Aumento della produttività. Python ci permette di risolvere problemi complessi in minor tempo usando poche righe di codice.</p>
                <p className='mb-4'>Python è diventato una soluzione in molte aree e in diverse applicazioni industriali, dallo sviluppo di applicazioni web al data science e machine learning.</p>
                <p className='mb-4'>È facile da imparare e comprendere rispetto ad altri linguaggi.</p>
                <p className='mb-4'>Enorme ecosistema che include librerie e framework.</p>
                <p className='mb-4'>Python è cross-platform, ciò significa che può essere utilizzato su Windows, Linux, e macOS</p>
                <p className='mb-4'>Community numerosa.</p>
                <p className='mb-4'>Domanda sempre crescente di sviluppatori Python.</p>
            </div>
            <MenuPython />
        </div>
    </div>
  )
}

export default python_cosepython