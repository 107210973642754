import { useState } from "react"
import { Link } from "react-router-dom"

const Navbar = () => {
    const [open, setOpen] = useState(false)
  return (
    <div className="h-20 w-[100vw] flex items-center justify-between px-8 bg-indigo-900 z-50 fixed top-0 left-0">
        <Link to="/">
            <h1 className="mr-4 text-xl text-white">
                <span className="text-indigo-400">&lt;rv&gt;</span>
                WebDev
                <span className="text-indigo-400">&lt;/rv&gt;</span>
            </h1>
        </Link>
        <div
            className='p-1 hover:bg-black/25 hover:cursor-pointer rounded hover:scale-[1.1] relative'
            onClick={() => setOpen(true)}
        >
            <span class="flex h-3 w-3 absolute left-0 top-0">
                <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-indigo-400 opacity-75"></span>
                <span class="relative inline-flex rounded-full h-3 w-3 bg-indigo-500"></span>
            </span>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-8 h-8 text-gray-200">
                <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
            </svg>
        </div>
        {open && (
            <>
            <div
                className="fixed top-0 right-0 h-[100vh] lg:w-[400px] sm:w-[100vw] bg-neutral-800 z-50"
            >
                <div className="flex w-full items-center justify-between">
                    <button 
                        className="m-4 rounded-full hover:bg-neutral-600 p-1 transition"
                        onClick={() => setOpen(false)}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-8 h-8 text-white">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </button>
                        <h1 className="mr-4 text-lg text-white">
                            <span className="text-indigo-500">&lt;rv&gt;</span>
                                WebDev
                            <span className="text-indigo-500">&lt;/rv&gt;</span>
                        </h1>
                </div>
                <ul className="mt-8">
                    <li>
                        <Link 
                            to={'/'} 
                            className='mx-4 my-3 flex p-2 items-center text-gray-200 rounded hover:bg-black/25'
                            onClick={() => setOpen(false)}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5 mr-1">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25" />
                            </svg>
                            <h1 className="text-lg">Home</h1>
                        </Link>
                    </li>
                    <li>
                        <Link 
                            to={'/about'} 
                            className='mx-4 my-3 flex p-2 items-center text-gray-200 rounded hover:bg-black/25'
                            onClick={() => setOpen(false)}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5 mr-1">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z" />
                            </svg>
                            <h1 className="text-lg">Su di me</h1>
                        </Link>
                    </li>
                    <li>
                        <Link 
                            to={'/projects'} 
                            className='mx-4 my-3 flex p-2 items-center text-gray-200 rounded hover:bg-black/25'
                            onClick={() => setOpen(false)}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5 mr-1">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 12.75V12A2.25 2.25 0 014.5 9.75h15A2.25 2.25 0 0121.75 12v.75m-8.69-6.44l-2.12-2.12a1.5 1.5 0 00-1.061-.44H4.5A2.25 2.25 0 002.25 6v12a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 18V9a2.25 2.25 0 00-2.25-2.25h-5.379a1.5 1.5 0 01-1.06-.44z" />
                            </svg>
                            <h1 className="text-lg">Progetti</h1>
                        </Link>
                    </li>
                    {/* <li>
                        <Link 
                            to={'/articles'} 
                            className='mx-4 my-3 flex p-2 items-center text-gray-200 rounded hover:bg-black/25'
                            onClick={() => setOpen(false)}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5 mr-1">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M12 6.042A8.967 8.967 0 006 3.75c-1.052 0-2.062.18-3 .512v14.25A8.987 8.987 0 016 18c2.305 0 4.408.867 6 2.292m0-14.25a8.966 8.966 0 016-2.292c1.052 0 2.062.18 3 .512v14.25A8.987 8.987 0 0018 18a8.967 8.967 0 00-6 2.292m0-14.25v14.25" />
                            </svg>
                            <h1 className="text-lg">Articoli</h1>
                        </Link>
                    </li> */}
                </ul>
                <div className="my-4 mx-4 h-[1px] bg-gray-200"></div>
                <p className="text-gray-200 px-4">Per qualsiasi informazione, richiesta di collaborazione o proposta lavorativa. Puoi trovare i miei contatti qui sotto.</p>
                <div className="my-4 mx-4 h-[1px] bg-gray-200"></div>
                <ul>
                    <li className="flex items-center text-gray-200 px-4 mb-3 text-lg">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5 mr-1">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M21.75 9v.906a2.25 2.25 0 01-1.183 1.981l-6.478 3.488M2.25 9v.906a2.25 2.25 0 001.183 1.981l6.478 3.488m8.839 2.51l-4.66-2.51m0 0l-1.023-.55a2.25 2.25 0 00-2.134 0l-1.022.55m0 0l-4.661 2.51m16.5 1.615a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V8.844a2.25 2.25 0 011.183-1.98l7.5-4.04a2.25 2.25 0 012.134 0l7.5 4.04a2.25 2.25 0 011.183 1.98V19.5z" />
                        </svg>
                        <h2>riccardo.vercellesi@gmail.com</h2>
                    </li>
                    <li className="flex items-center text-gray-200 px-4 mb-3 text-lg">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5 mr-1">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z" />
                        </svg>
                        <h2>+39 348 5542839</h2>
                    </li>
                    <li className="flex items-center text-gray-200 px-4 mb-3 text-lg">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5 mr-1">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244" />
                        </svg>
                        <a href="https://www.linkedin.com/in/riccardo-vercellesi-b19a16176/" target="_blank" rel="noreferrer">LinkedIn</a>
                    </li>
                    <li className="flex items-center text-gray-200 px-4 mb-3 text-lg">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5 mr-1">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244" />
                        </svg>
                        <a href="https://github.com/RiccardoV1995?tab=repositories" target="_blank" rel="noreferrer">GitHub</a>
                    </li>
                </ul>
                <h2 className="absolute bottom-4 w-full text-center text-gray-200 py-4">Creato da Riccardo Vercellesi &copy;</h2>
            </div>
            <div onClick={() => setOpen(false)} className="fixed top-0 left-0 w-full h-full bg-black/30"></div>
            </>
        )}
    </div>
  )
}

export default Navbar