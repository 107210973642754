import React from 'react'

const Footer = () => {
  return (
    <div className="w-full h-[20px] bg-neutral-700 flex items-center justify-center p-10">
        <h4 className="text-white text-lg">Creato da Riccardo Vercellesi</h4>
    </div>
  )
}

export default Footer