import { useState } from 'react'

const Calculator = () => {
    const [result, setResult] = useState('')

    const handleClick = (e) => {
        setResult(result.concat(e))
    }

    const calcucate = () => {
        setResult(eval(result).toString())
    }

  return (
    <div className='min-h-[100vh] w-full flex flex-col items-center justify-center bg-gray-200'>
        <h1 className='my-6 text-4xl text-gray-800 font-semibold'>Calcolatrice</h1>
        <div className='grid w-[350px] grid-cols-4 shadow-md bg-slate-900 '>
            {/* display */}
            <div className='col-span-4 h-28 bg-gray-900 text-white border border-gray-600 flex flex-col items-end justify-center p-4 text-2xl'>
                <div>{ result }</div>
            </div>

            <button className='col-span-2 bg-slate-800 hover:bg-slate-900 transition text-gray-100 border border-gray-600 p-6 active:text-sm' onClick={() => setResult('')}>AC</button>
            <button className='bg-slate-800 hover:bg-slate-900 transition text-gray-100 border border-gray-600 p-6 active:text-sm' onClick={() => setResult(result.slice(0, -1))}>DEL</button>
            <button className='bg-slate-800 hover:bg-slate-900 transition text-gray-100 border border-gray-600 p-6 active:text-sm' name='/' onClick={(e) => handleClick(e.target.name)}>/</button>

            <button className='bg-slate-800 hover:bg-slate-900 transition text-gray-100 border border-gray-600 p-6 active:text-sm' name='1' onClick={(e) => handleClick(e.target.name)}>1</button>
            <button className='bg-slate-800 hover:bg-slate-900 transition text-gray-100 border border-gray-600 p-6 active:text-sm' name='2' onClick={(e) => handleClick(e.target.name)}>2</button>
            <button className='bg-slate-800 hover:bg-slate-900 transition text-gray-100 border border-gray-600 p-6 active:text-sm' name='3' onClick={(e) => handleClick(e.target.name)}>3</button>
            <button className='bg-slate-800 hover:bg-slate-900 transition text-gray-100 border border-gray-600 p-6 active:text-sm' name='*' onClick={(e) => handleClick(e.target.name)}>*</button>

            <button className='bg-slate-800 hover:bg-slate-900 transition text-gray-100 border border-gray-600 p-6 active:text-sm' name='4' onClick={(e) => handleClick(e.target.name)}>4</button>
            <button className='bg-slate-800 hover:bg-slate-900 transition text-gray-100 border border-gray-600 p-6 active:text-sm' name='5' onClick={(e) => handleClick(e.target.name)}>5</button>
            <button className='bg-slate-800 hover:bg-slate-900 transition text-gray-100 border border-gray-600 p-6 active:text-sm' name='6' onClick={(e) => handleClick(e.target.name)}>6</button>
            <button className='bg-slate-800 hover:bg-slate-900 transition text-gray-100 border border-gray-600 p-6 active:text-sm' name='+' onClick={(e) => handleClick(e.target.name)}>+</button>

            <button className='bg-slate-800 hover:bg-slate-900 transition text-gray-100 border border-gray-600 p-6 active:text-sm' name='7' onClick={(e) => handleClick(e.target.name)}>7</button>
            <button className='bg-slate-800 hover:bg-slate-900 transition text-gray-100 border border-gray-600 p-6 active:text-sm' name='8' onClick={(e) => handleClick(e.target.name)}>8</button>
            <button className='bg-slate-800 hover:bg-slate-900 transition text-gray-100 border border-gray-600 p-6 active:text-sm' name='9' onClick={(e) => handleClick(e.target.name)}>9</button>
            <button className='bg-slate-800 hover:bg-slate-900 transition text-gray-100 border border-gray-600 p-6 active:text-sm' name='-' onClick={(e) => handleClick(e.target.name)}>-</button>

            <button className='bg-slate-800 hover:bg-slate-900 transition text-gray-100 border border-gray-600 p-6 active:text-sm' name='.' onClick={(e) => handleClick(e.target.name)}>.</button>
            <button className='bg-slate-800 hover:bg-slate-900 transition text-gray-100 border border-gray-600 p-6 active:text-sm' name='0' onClick={(e) => handleClick(e.target.name)}>0</button>
            <button className='col-span-2 bg-slate-800 hover:bg-slate-900 transition text-gray-100 border border-gray-600 p-6 active:text-sm' onClick={calcucate}>=</button>
        </div>
    </div>
  )
}

export default Calculator